import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import classnames from 'classnames'
import './Files.css'
import { getFile } from '../utils/network'
import {FilePlus} from 'react-feather'
import { defaultT } from '../utils/i18n'
import { TrashCell } from './DataTable'
import moment from "moment"
import { ProgressBar } from './ProgressBar/ProgressBar'
import axios from 'axios'

class SingleFile extends Component {
    state = {
        sortBy: 'date',
        sortDirection: 'DESC',
        uploadState: 0,
        hovered: false,
    }

    handleResponse(data, t) {
        const { onChange, user, anonymous } = this.props
        if (data) {
            const userObject = !!anonymous ? {} : {user}
            onChange({
                ...data,
                ...userObject,
                date: moment().format('YYYY-MM-DD HH:mm')
            })
        }
        else {
            toastr.error(t('errorUploading'))
        }
    }

    handleFileUpload(event, t) {
        const files = event.target.files
        const file = files[0]
        let data = new FormData()
        const fileName = file.name
        const fileType = file.type
        const fileSize = file.size
        const chunkSize = 15 * 1024 * 1024 // 15MB
        let fileChunk = null, chunkNb = 0

        for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
            if (start + chunkSize > fileSize) {
                fileChunk = file.slice(start, fileSize)
            }
            else {
                fileChunk = file.slice(start, start + chunkSize)
            }
            data.append('fileChunks', fileChunk, fileName)
        }
        data.append('fileType', fileType)
        data.append('fileSize', fileSize)
        data.append('chunkNb', chunkNb)

        const options = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor( (loaded * 100) / total )
                this.setState({ uploadState: percent })
            }
        }
        axios.post(`/${this.props.url}`, data, options)
            .then(res => {
                this.handleResponse(res.data, t)
                toastr.success(t('successFileUploading'))
                this.setState({ uploadState: 0 })
            })
            .catch(err => {
                const message = _.get(err, 'response.data')
                console.log(err)
                if(message) {
                    toastr.error(t(message))
                    this.setState({ uploadState: 0 })
                } else {
                    toastr.error(t('errorUploading'))
                    this.setState({ uploadState: 0 })
                }
                this.setState({ uploadState: 0 })
            })
        // Allow to re-upload the same file in a row
        event.target.value = null
    }

    handleDownload= event => {
        //event.stopPropagation()
        console.log('hello')
        const {value, language, groupModel, t} = this.props
        toastr.info(t('downloading'))
        getFile({
            fileId: value.id,
            filename: value.filename,
            language,
            groupModel
        })
    }

    render() {
        const { value, t = defaultT, placeholder, editable, style } = this.props

        if(value) {
            return (
                <div style={{display: 'flex', displayDirection: 'row', alignItems: 'center', marginTop: '5px'}}>
                    <button
                        style={{
                            border: '1px solid #dadada',
                            borderRadius: '15px',
                            background: '#f2f2f2'
                        }}
                        className="btn"
                        onClick={this.handleDownload}
                    >
                        {value.filename}
                    </button>
                    {editable && <TrashCell style={{marginLeft: '10px'}} size={17} onClick={() => this.props.onChange(null)} />}
                </div>
            )
        }
        return (
            <div className="Files-container" style={style}>
                <div className="align-horizontally">
                    <div style={{width: "260px"}}>
                        <button
                            disabled={!editable}
                            className={
                                classnames(
                                    "btn Files-button",
                                    {hovered: this.state.hovered}
                                )
                            }
                        >
                            <FilePlus size={16} style={{verticalAlign: 'middle', marginRight: '10px'}}/>
                            <span className="Files-button-Text">
                                    {t(placeholder || 'Ajouter un fichier', {capitalize: true})}
                                </span>
                        </button>
                        <input
                            disabled={!editable}
                            className="Files-input"
                            type="file"
                            encType='multipart/form-data'
                            onMouseEnter={() => this.setState({hovered: true})}
                            onMouseLeave={() => this.setState({hovered: false})}
                            onDragOver={() => this.setState({hovered: true})}
                            onChange={event => {
                                this.handleFileUpload(event, t)
                            }}
                            style={{width: '250px', height: '60px', left: '20px'}}
                        />
                    </div>
                    {value &&
                        <TrashCell style={{marginLeft: '10px'}} size={17} onClick={() => this.props.onChange(null)}/>}
                </div>
                {this.state.uploadState > 0 ?
                    <div className="uploadProgress">
                        {t('uploading')}
                        <ProgressBar percentage={this.state.uploadState} color='#E94E24'/>
                        {this.state.uploadState}%
                    </div>
                    : null
                }
            </div>
        )
    }
}

SingleFile.propTypes = {
    t: PropTypes.func
}

export default SingleFile
