import {basicContext} from "../../../../utils/contextUtils"

export const portefeuilleEntity = {
    name: "BusinessProjectPortfolio",
    fields: [
        "Country",
        "Customer",
        "TypeOfOffer",
        "ResponseMode",
        "OriginOfTheCase",
        {path: "beginYear", type: "Year"},
        {path: "commercialTeamMember", type: "TeamMember"},
        {path: "technicalTeamMember", type: "TeamMember"},
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) return callback(error)
            global.app.I.BusinessProject.find({
                ...basicContext(context),
                query: {},
                fieldPath: [
                    "country.name", "customer.name", "typeOfOffer.name", "fullName", "description", "responseMode.name", "beginYear.name", "originOfTheCase.name", "specialFollowUp.name",
                    "businessRevueCurrentTurnOver", "expectedNumberOfDays", "sumCommittedNumberOfDays", "sumActualNumberOfDays",
                    "technicalTeamMember.fullName", "commercialTeamMember.fullName", "referentInSupport.name", "projectManager.name", "projectTeam.name", "nextDeadLine",
                    "alert", "currentFunctions", "statusAndOrder",
                    //"file.id", "file.filename"
                ],
                data: context.data,
                filters: [
                    "byZone",
                    "byWorkflowStepPF"
                ]
            }, (e, businessProjects) => {
                if (e) {
                    global.ioSocket.emit(
                        `fetchDT-failure-${context.user.id}${context.clientContext.accessId}`,
                        {error: e.message}
                    )
                } else {
                    global.ioSocket.emit(
                        `fetchDT-success-${context.user.id}${context.clientContext.accessId}`,
                        businessProjects
                    )
                }
            })
            return callback()
        })
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R')
            .then(
                context => global.app.I.BusinessProject.get(id, {
                    ...basicContext(context),
                    fieldPath: [
                        "identifier", "typeOfOffer.name", "name",
                        "technicalTeamMember.fullName", "commercialTeamMember.fullName", "referentInSupport", "projectManager", "projectTeam", "expectedTurnOver", "nextDeadLine",
                        "businessRevueCurrentTurnOver", "operationsAndServicesContracts",
                        "alert", "currentFunctions", "statusAndOrder",
                        "files.id", "comments.id"
                    ]}, callback)
            )
    },
    save: function(businessProject, context, callback) {
        this.prepareContext(context, 'S')
            .then(() => {
                global.db.collection("i.businessProject").updateOne(
                    {_id: new global.ObjectID(businessProject.id)},
                    {
                        $set: {
                            typeOfOffer: new global.ObjectID(businessProject.typeOfOffer.id),
                            technicalTeamMember: new global.ObjectID(businessProject.technicalTeamMember.id),
                            referentInSupport: businessProject.referentInSupport,
                            projectManager: businessProject.projectManager,
                            projectTeam: businessProject.projectTeam,
                            nextDeadLine: businessProject.nextDeadLine,
                            alert: businessProject.alert,
                        }
                    }, (e) => {
                        global.app.I.BusinessProject.get(businessProject.id, {
                            ...basicContext(context),
                            fieldPath: [
                                "country.name", "customer.name", "typeOfOffer.name", "fullName", "description", "responseMode.name", "beginYear.name", "originOfTheCase.name", "specialFollowUp.name",
                                "businessRevueCurrentTurnOver", "expectedNumberOfDays", "sumCommittedNumberOfDays", "sumActualNumberOfDays",
                                "technicalTeamMember.fullName", "commercialTeamMember.fullName", "referentInSupport", "projectManager", "projectTeam.name", "nextDeadLine",
                                "alert", "currentFunctions", "statusAndOrder",
                            ]}, callback
                        )
                    }
                )
            })
    }
}


export const portefeuille = {
    name: "bp6",
    object: "BusinessProjectPortfolio",
    category: {
        path: "technical",
        icon: "layers"
    },
    newable: false,
    removable: false,
    useSocketsOnFind: true,
    viewMap: {
        dt: [
            "country",
            {path: "customer", width: 200},
            {path: "typeOfOffer", width: 300},
            {path: "fullName", tKey: 'name', width: 400},
            {path: "description", width: 300, initiallyNotVisible: true},
            {path: "responseMode", initiallyNotVisible: true},
            {path: "beginYear", width: 80},
            {path: "originOfTheCase", initiallyNotVisible: true},
            {path: "specialFollowUp", type: "boolean", initiallyNotVisible: true},

            {path: "businessRevueCurrentTurnOver", width: 80, tooltip: true},
            {path: "expectedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumCommittedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumActualNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},

            {path: "technicalTeamMember", display: "fullName", width: 150},
            {
                path: "commercialTeamMember",
                display: "fullName",
                width: 150,
                initiallyNotVisible: true
            },
            {path: "referentInSupport", width: 100, initiallyNotVisible: true},
            {path: "projectManager", width: 150, initiallyNotVisible: true},
            {path: "projectTeam", width: 150, initiallyNotVisible: true},
            {path: "nextDeadLine", width: 150, initiallyNotVisible: true},
            {path: "alert", width: 150, initiallyNotVisible: true},
            {path: "currentFunctions", width: 100},
            {path: "statusAndOrder", width: 100, initiallyNotVisible: true},

            {path: "file", tKey: "FA", type: "fileLink"}
        ],
        form: {
            fields: [
                {path: "identifier", disabled: true},
                {path: "typeOfOffer"},
                {path: "name", disabled: true},
                {path: "commercialTeamMember", display: "fullName", filters: ["commercialReferent"], disabled: true},
                {path: "technicalTeamMember", display: "fullName", filters: ["technicalReferent"]},
                {path: "referentInSupport"},
                {path: "projectManager"},
                {path: "projectTeam", type: "textarea"},
                {path: "businessRevueCurrentTurnOver", disabled: true},
                {path: "expectedTurnOver", disabled: true},

                {path: "nextDeadLine", type: "datePicker"},
                {path: "alert"},

                {path: "operationsAndServicesContracts", type: 'creatableTags', disabled: true},
                {path: "comments", type: "comment2", editable: false},
                {path: "files", type: "files2", editable: false},
                {path: "workflow", hidden: true},

                {path: "businessProject", hidden: true}
            ],
            buttons: [{type: "cancel"}]
        }
    },
    filters: [
        {
            title: "Zone",
            path: "zone",
            object: "Zone",
            display: "name",
            filters: ["inUserTeam"],
            objectFieldPath: ["country.zone.id"],
            client: true,
            default: {id: null}
        },
        {
            path: "workflowStep",
            object: "WorkflowStep",
            type: "tags",
            client: true,
            timer: 3,
            display: "name",
            default: [{id: 'formX'}, {id: 'buildOffer'}, {id: 'submitOffer'}, {id: 'realisation'}],
        }
    ]
}
