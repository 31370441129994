const _ = require("lodash");
const { translateName } = require("../../../utils/i18n");
const { joinEntities } = require("./scopeEntityJoin");

export const entity = joinEntities({
    name: "Scope",
    fields: [
        "code",
        "name",

        {
            nullable: true,
            type: "OrganizationAxis",
            link: "MTO"
        },

        {
            nullable: true,
            type: "FamilyAxis",
            link: "MTO"
        },

        {
            path: "scopeAxe",
            fieldPath: [
                "organizationAxis.id", "organizationAxis.code", "organizationAxis.name", "organizationAxis.tName",
                "familyAxis.id", "familyAxis.code", "familyAxis.name", "familyAxis.tName"
            ],
            f: function() {
                switch (this.joinedEntity) {
                    case "Family":
                        return this.familyAxis;
                    case "Organization":
                        return this.organizationAxis;
                    // case "Product":
                    //     return global.app.S.ProductAxis.objects[0];
                    // case "Store":
                    //     return global.app.S.StoreAxis.objects[0];
                    default:
                        return null;
                }
            }
        },
        {
            path: "completeName",
            fieldPath: ["name", "scopeAxe.tName"],
            $f: (object, context, callback) => {
                const tName = translateName(object.name, _.get(context, "language.id"));
                const completeName = object.scopeAxe && object.scopeAxe.tName + " : " + tName;
                callback(null, completeName);
            }
        },

        {
            path: "filterObjectForMongo",
            fieldPath: ["code", "scopeAxe.code"],
            f: function() {
                return _.set({}, this.scopeAxe.code, this.code);
            }
        },

        {
            path: "ancestorEntityName",
            f: function() {
                return this.joinedEntity || "Scope";
            }
        }
    ]
}, [
    "Family",
    "Organization",
    // "Store",
    // "Product"
])

export const module_ = {
    object: "Scope",
    category: "Habilitation",
    viewMap: {
        dt: ["name"]
    }
}
