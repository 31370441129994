export const module_ = {
    object: 'Payment',
    name: 'ReliefFundPaymentValidation',
    tKey: 'Validation paiement',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            { path: 'reliedFundApplicationNumber', tKey: 'applicationNumber'},
            { path: 'need', tKey: 'Besoin', translate: true },
            { path: 'awardedAmount' },
            { path: 'amount', tKey: 'Mt (€)' },
            { path: 'account' },
            { path: 'status', type: 'status', fieldPath: ['id', 'name', 'style'], translate: true },
        ],
        form: [
            { path: 'reliedFundApplicationNumber', tKey: 'applicationNumber', editable: false},
            { path: 'need', tKey: 'Besoin', editable: false },
            { path: 'awardedAmount', editable: false },
            { path: 'amount', tKey: 'Mt (€)', editable: false },
            { path: 'account', editable: false },
            'comments',
            { path: 'status', hidden: true },
            { path: 'buttons', hidden: true }
        ]
    }
}
