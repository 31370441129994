import _ from "lodash";
import {returnButton, saveButton} from "../utils";

export const presentButton = nextStatus => ({
    action: 'present',
    type: 'action',
    nextStatus,
    //nextStatus: 'readyToBePresented',
    tKey: 'Présenter',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
})

export const adjourn = {
    action: 'adjourn',
    type: 'action',
    nextStatus: 'incomplete',
    tKey: 'Demander compléments',
    bsStyle: 'warning',
    bypassFormValidation: true,
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
}


export const validateButton = {
    action: 'validate',
    type: 'action',
    nextStatus: 'validated',
    tKey: 'Valider',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
}

export const rejectButton = {
    action: 'reject',
    type: 'action',
    nextStatus: 'rejected',
    tKey: 'Refuser',
    bsStyle: 'danger',
    bypassFormValidation: true,
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
}

export const prepareListButton = {
    type: 'action',
    action: 'prepareList',
    tKey: 'Préparer la liste',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
}

export const generateReportButton = {
    type: 'action',
    action: 'generateReport',
    tKey: 'Générer et envoyer le relevé de décisions',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
}

export const getButtonsForModule = (file, context) => {
    const statusId = _.get(file, 'status.id')
    const { moduleId } = context.clientContext

    if( moduleId === 'm-C-liberalityFile') {
        if(statusId === 'draft') return [presentButton('readyToBePresented'), saveButton, returnButton]
        if(statusId === 'incomplete') return [presentButton('updated'), saveButton, returnButton]
    }

    if( moduleId === 'm-C-liberalityDecision') {
        if(statusId === 'awaitingDecision') return [validateButton, rejectButton, adjourn, saveButton, returnButton]
    }

    return [returnButton]
}
