import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import _ from 'lodash'
import classnames from 'classnames'
import './Files.css'
import './SimpleFiles.css'
import { getFile } from '../utils/network'
import {FilePlus} from 'react-feather'
import { defaultT } from '../utils/i18n'
import { TrashCell } from './DataTable'
import moment from "moment"
import { ProgressBar } from './ProgressBar/ProgressBar'

class SimpleFiles extends Component {
    state = {
        uploadState: 0,
        hovered: false,
    }

    handleResponse(data, t) {
        const { push, user, anonymous } = this.props
        if (data) {
            const userObject = !!anonymous ? {} : {user}
            push({
                ...data,
                ...userObject,
                date: moment().format('YYYY-MM-DD HH:mm')
            })
        }
        else {
            toastr.error(t('errorUploading'))
        }
    }

    handleFileUpload(event, t) {
        const files = event.target.files
        const file = files[0]
        let data = new FormData()
        const fileName = `${moment().format('YYYY-MM-DD_HH:mm:ss')}_${file.name}`
        const fileType = file.type
        const fileSize = file.size
        const chunkSize = 15 * 1024 * 1024 // 15MB
        let fileChunk = null, chunkNb = 0

        for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
            if (start + chunkSize > fileSize) {
                fileChunk = file.slice(start, fileSize)
            }
            else {
                fileChunk = file.slice(start, start + chunkSize)
            }
            data.append('fileChunks', fileChunk, fileName)
        }
        data.append('fileType', fileType)
        data.append('fileSize', fileSize)
        data.append('chunkNb', chunkNb)

        const options = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                let percent = Math.floor( (loaded * 100) / total )
                this.setState({ uploadState: percent })
            }
        }
        axios.post(`/${this.props.url}`, data, options)
            .then(res => {
                this.handleResponse(res.data, t)
                toastr.success(t('successFileUploading'))
                this.setState({ uploadState: 0 })
            })
            .catch(err => {
                const message = _.get(err, 'response.data')
                console.log(err)
                if(message) {
                    toastr.error(t(message))
                    this.setState({ uploadState: 0 })
                } else {
                    toastr.error(t('errorUploading'))
                    this.setState({ uploadState: 0 })
                }
                this.setState({ uploadState: 0 })
            })
        // Allow to re-upload the same file in a row
        event.target.value = null
    }

    findRowIndex = (rowId) => {
        const { allIds } = this.props

        return allIds.findIndex(id => id === rowId)
    }

    renderDeleteIcon = (file) => {
        return <TrashCell style={{marginLeft: '6px'}} onClick={() => {
            const index = this.findRowIndex(file.id)
            this.props.remove(index)
        }} />
    }

    handleDownload = (file) => {
        const {t, language, groupModel} = this.props
        toastr.info(t('downloading'))
        getFile({
            fileId: file.id,
            filename: file.filename,
            language,
            groupModel
        })
    }

    render() {
        const { allIds = [], byId = {}, t = defaultT, placeholder, editable, style } = this.props

        return (
            <div className="Files-container" style={style}>
                {editable && <div className="align-horizontally" style={{marginBottom: '10px'}}>
                    <div style={{width: "260px"}}>
                        <button
                            type="button"
                            disabled={!editable}
                            className={
                                classnames(
                                    "btn Files-button",
                                    {hovered: this.state.hovered}
                                )
                            }
                        >
                            <FilePlus size={16} style={{verticalAlign: 'middle', marginRight: '10px'}}/>
                            <span className="Files-button-Text">
                                    {t(placeholder || 'Ajouter un fichier', {capitalize: true})}
                                </span>
                        </button>
                        <input
                            disabled={!editable}
                            className="Files-input"
                            type="file"
                            encType='multipart/form-data'
                            onMouseEnter={() => this.setState({hovered: true})}
                            onMouseLeave={() => this.setState({hovered: false})}
                            onDragOver={() => this.setState({hovered: true})}
                            onChange={event => {
                                this.handleFileUpload(event, t)
                            }}
                            style={{width: '250px', height: '60px', left: '20px'}}
                        />
                    </div>
                </div>}
                {this.state.uploadState > 0 ?
                    <div className="uploadProgress">
                        {t('uploading')}
                        <ProgressBar percentage={this.state.uploadState} color='#E94E24'/>
                        {this.state.uploadState}%
                    </div>
                    : null
                }
                {
                    <>
                        {allIds.map(id => {
                            const file = byId[id]
                            return (
                                <div style={{
                                    display: 'flex',
                                    displayDirection: 'row',
                                    alignItems: 'center',
                                    marginTop: '5px',
                                    marginBottom: '3px'
                                }}>
                                    <button
                                        style={{
                                            border: '1px solid #dadada',
                                            borderRadius: '12px',
                                            background: '#f2f2f2'
                                        }}
                                        className="btn"
                                        onClick={() => this.handleDownload(file)}
                                    >
                                        {file.filename}
                                    </button>
                                    {editable && this.renderDeleteIcon(file)}
                                </div>
                            )

                        })}
                    </>
                }
            </div>
        )
    }
}

SimpleFiles.propTypes = {
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default SimpleFiles
