// const {objectIsNew, objectIsNotNew} = require("../../utils/kp3Utils");
import {generateFetchFieldListAction} from "../../../apps/KpModule/actions/api";
import async from "async";
import moment from "moment";
import {changeFieldProperty} from "../../../apps/KpModule/actions";

const _ = require("lodash");
const { setFieldVisibility, changeFieldDisabled  } = require("../../../apps/KpModule/actions");
const Errors = require('../../utils/Errors').default

const getFieldsModuleForm = (fieldPaths, module) => {
    return fieldPaths.map(fieldPath => (
        module.viewMap.form.fields.find(
            field => field.path === fieldPath
        )
    ))
}

export const dependencies = [
    {
        name: "ScoringField",
        type: "mongoInternal",
        fields: [
            {path: "type", type: "RuleType"},
            {path: "numeratorTheme", type: "ThemeJoin", link: "MTO"},
            {path: "numeratorFieldType", type: "ThemeFieldType", link: "MTO"},
            {path: "denominatorTheme", type: "ThemeJoin", link: "MTO", nullable: true},
            {path: "denominatorFieldType", type: "ThemeFieldType", link: "MTO", nullable: true},
            {path: "lookupGrowth", type: "boolean", default: true},
            {path: "coefficient", type: "decimal"}
        ]
    }
]

export const entity = {
    name: "ScoringConfig",
    facets: ["description", "translatedField"],
    fields: [
        {path: "code", notEmpty: true, unique: true},
        {path: "name", notEmpty: true, unique: true},
        "description",
        {
            path: 'scoredElements',
            type: "GroupAxis",
            link: "MTM",
        },
        {path: "filterFields", type: "Scope", link: "MTM", nullable: true},
        {type: "ComparisonAxis", link: "MTO", nullable: true},
        {type: "TimeWindow", link: "MTO"},
        {path: "fromFiscalYearStart", type: "boolean"},
        {
            path: "fiscalYearStart",
            $f: function (object, context, callback) {
                global.app.S.GeneralSettings.collection
                    .findOne(
                        {group: global.ObjectID(context.group.id)},
                        (e, generalSettings) => {
                            if(!generalSettings) return callback(null,  moment.utc('01-01', 'MM-DD'))
                            return callback(null, moment.utc(`${generalSettings.month}-${generalSettings.monthNumber}`, 'MM-DD'))
                        }
                    )
            }
        },
        {
            path: "delay",
            $f: function (object, context, callback) {
                global.app.S.GeneralSettings.collection
                    .findOne(
                        {group: global.ObjectID(context.group.id)},
                        (e, generalSettings) => {
                            if(!generalSettings) return callback(null, 1)
                            return callback(null, generalSettings.delay)
                        }
                    )
            }
        },
        {type: "WeekDay", link: "MTO", nullable: true},
        {type: "MonthDay", link: "MTO", nullable: true},
        {path: "sendJournal", type: "boolean", nullable: true},
        {path: "dormant", type: "boolean", default: false},
        {
            path: "habFunctions",
            type: "HabFunction",
            link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}},
            nullable: true
        },
        {
            type: "ScoringField",
            link: "OTM",
            notEmpty: true,
            ps: {
                object: [
                    {
                        $v: function (scoringFields) {
                            if (_.some(scoringFields, value => value.coefficient < 1)) return new Errors.ValidationError(this.options.context.tc("M_S.coefficientsMustBePositive"));
                        }
                    }
                ]
            }
        },
        {
            path: "filterFieldsName",
            fieldPath: ["filterFields.name"],
            f: function(){
                return this.filterFields.length ? this.filterFields.map(filterField => filterField.name).join(", ") : "All";
            }
        },
        {
            path: "scoredElementsSources",
            fieldPath: ["scoredElements.joinedEntity"],
            f: function () { return this.scoredElements.map(scoredElement => scoredElement.joinedEntity) }
        },
        {
            path: "activationDay",
            fieldPath: ["weekDay", "monthDay"],
            f: function() {
                return this.weekDay ? (this.monthDay ? this.weekDay.name + ", " + this.monthDay.name : this.weekDay.name) : (this.monthDay ? this.monthDay.name : "Every Day")
            }
        },
        {
            path: 'hasCalculatedData',
            $f: (scoringConfig, context, callback) => {
                global.app.S.Scoring.collection
                    .findOne(
                        {scoringConfig: new global.ObjectID(scoringConfig.id)},
                        (e, scoring) => {
                            if (e) return callback(e)
                            callback(null, !!scoring)
                        })
            }
        }
    ],
    validateDelete: function (object, context, callback) {
        async.parallel([
            callback =>  global.app.S.ScoreLine.collection.deleteMany({scoringConfig: global.ObjectID(object.id)}, callback),
            callback =>  global.app.S.Scoring.collection.deleteMany({ scoringConfig: global.ObjectID(object.id) }, callback),
        ], (e) => {
            if(e) return callback(e)
            return callback(null, object)
        })
    },
    filters: [
        {
            name: "scoringConfigurationsByHabFunctions",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                global.app.S.User.getUserHabFunctions(
                    context.user,
                    context,
                    function (e, userHabFunctions) {
                        callback(
                            e,
                            {$or: [
                                    {habFunctions: {$size: 0}},
                                    {habFunctions: {$in: userHabFunctions.map(hf => new global.ObjectID(hf.id))}}
                                ]}
                        )
                    }
                )
            }
        }
    ]
}

export const module_ = {
    object: "ScoringConfig",
    tKey: "mTitle_scoringConfig",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "name",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            {path: "name", type: "translatedText", width: 300},
            {path: "filterFieldsName", tKey: "dataPerimeter", width: 80},
            {path: "scoredElements", tKey: "scoredObject", display: "tName", translateName: true, width: 80},
            {path: "comparisonAxis", tKey: "comparisonGroup", display: "tName", width: 80},
            {path: "timeWindow", tKey: "dataPeriod", display: "tKey", translate: true, width: 50},
            {path: "habFunctions", tKey: "profile(s)", translateName: true, width: 100, initiallyNotVisible: true},
            {path: "dormant", tKey: "suspended", width: 50},
        ],
        form: {
            fields: [
                {path: "code"},
                {path: "name", type: "textarea"},
                "description",

                {path: "filterFields", tKey: "dataPerimeter", display: "completeName"},
                {
                    path: "scoredElements",
                    tKey: "scoredObject",
                    display: "tName",
                    translateName: true,
                    fieldPath: ["id", "tName", "joinedEntity"],
                    filters: ["scoringAnalysisAxes"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-S-scoringConfig.ScoringConfig_comparisonAxis",
                                    store.getState,
                                    'form',
                                    {
                                        data: {scoredObject: newValue}
                                    }
                                )
                            )
                        }
                    }
                },
                {
                    path: "comparisonAxis",
                    tKey: "comparisonGroup",
                    display: "tName",
                    filters: ["byScoredObject"]
                },
                {
                    path: "timeWindow",
                    tKey: "dataPeriod",
                    display: "tKey",
                    translate: true,
                    clearable: false,
                    sortList: false,
                    default: {id: '00000000000000000000000a'},
                    filters: ["noFiscalYear"]
                },
                // {path: "sendJournal", tKey: "includeInJournal"},
                {
                    path: "scoringFields",
                    tKey: "criteria",
                    required: true,
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "numeratorTheme", tKey: "indicatorOrNumerator", display: "completeName"},
                            {path: "numeratorFieldType", tKey: "amountOrNumber", display: "tKey", translate: true},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName"},
                            {path: "denominatorFieldType", tKey: "amountOrNumber", display: "tKey", translate: true},
                            {path: "coefficient", tKey: "importanceCoefficient"}
                        ],
                        form: [
                            {
                                path: "type",
                                type: 'toggle',
                                display: "name2",
                                sortList: false,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const scoringFieldsField = module.viewMap.form.fields.find(field => field.path === "scoringFields")
                                        const getField = path => scoringFieldsField.viewMap.form.fields.find(field => field.path === path )

                                        const simpleRuleFields = ["numeratorTheme", "numeratorFieldType", "lookupGrowth", "coefficient"].map(path => getField(path))
                                        const ratioRuleFields = ["denominatorTheme", "denominatorFieldType"].map(path => getField(path))

                                        simpleRuleFields.forEach(field => store.dispatch(setFieldVisibility(field.id, newValue && ["simple", "ratio"].includes(newValue.id))))
                                        ratioRuleFields.forEach(field => store.dispatch(setFieldVisibility(field.id, newValue?.id === "ratio")))

                                        if(newValue?.id === 'simple') {
                                            ratioRuleFields.forEach(field => field.setValue(null))
                                        }

                                        store.dispatch(changeFieldProperty("e_scoringFields.e_numeratorTheme", "tKey", newValue?.id === "simple" ? "indicator" : "numerator"))
                                    }
                                }
                            },
                            {path: "numeratorTheme", tKey: "numerator", display: "completeName", searchInList: true, hidden: true},
                            {path: "numeratorFieldType", tKey: "amountOrNumber", display: "tKey", type: 'toggle', translate: true, hidden: true},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName", searchInList: true, hidden: true},
                            {path: "denominatorFieldType", tKey: "amountOrNumber", display: "tKey", type: 'toggle', translate: true, hidden: true},
                            {path: "lookupGrowth", hidden: true},
                            {path: "coefficient", tKey: "importanceCoefficient", default: 1, required: true, wholePositiveNumber: true, hidden: true}
                        ]
                    }
                },
                {path: "habFunctions", tKey: "habilitation", translateName: true},
                {path: "dormant", tKey: "suspend"},
                {path: 'hasCalculatedData', hidden: true}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const objectMode = state.ui.objectMode
                const hasCalculatedData = state.edit.object.data.hasCalculatedData

                const fields = [
                    'e_code', 'e_filterFields', 'e_scoredElements', 'e_comparisonAxis',
                    'e_scoringFields', 'e_timeWindow', 'e_alertFields'
                ]
                fields.forEach(field => store.dispatch(changeFieldDisabled(field, hasCalculatedData)))
            }
        }
    }
}
