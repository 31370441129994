import {getButtonsForModule, getUsersWithAuthorization, prepareDefaultMail} from "./utils"
import _ from "lodash";
import moment from "moment/moment";
import {basicContext} from "../../../utils/contextUtils";
import async from "async";
import Errors from "../../../utils/Errors";
import {decrypt} from "../../../utils/crypto";

export const entities = [
    {
        name: 'Payment',
        facets: [
            'comments'
        ],
        fields: [
            {type: 'Need'},
            {
                path: 'reliedFundApplicationNumber',
                fieldPath: ['need.reliefFund.applicationNumber'],
                f: function() {
                    return this.need?.reliefFund.applicationNumber
                }
            },
            {
                path: 'awardedAmount',
                fieldPath: ['need.awardedAmount'],
                f: function() {
                    return this.need?.awardedAmount
                }
            },
            {path: 'amount', type: 'integer'},
            {path: 'account', type: 'Bank'},
            {path: 'status', type: 'ReliefFundPaymentStatus', nullable: true},
            {
                path: 'buttons',
                fieldPath: ['status.id'],
                $f: function (payment, context, callback) {
                    const buttons = getButtonsForModule(payment, context);
                    callback(null, buttons);
                }
            },
            /*
            {
                path: 'balance',
                fieldPath: ['awardedAmount'],
                f: function() {
                    return this.awardedAmount
                }
            },
             */

        ],
        authorizationsValidation: function (cUser, context, callback) {
            const moduleId = context.clientContext.moduleId
            const authorizationProperty = 'decisionAuthorization'

            const hasAuthorization = cUser.functions.some(userFunction => !!userFunction[authorizationProperty])

            if (moduleId === 'm-C-reliefFundPaymentValidation' && !hasAuthorization) {
                return callback(new Errors.ValidationError(`${authorizationProperty}Denied`))
            }

            return callback()
        },
        validateSave: async function (newObject, oldObject, context, callback) {
            const cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(context.user.id)}, {
                ...basicContext(context),
                fieldPath: [
                    'id',
                    'functions.id',
                    'functions.submissionAuthorization',
                    'functions.studyAuthorization',
                    'functions.decisionAuthorization',
                    'functions.paymentAuthorization',
                    'organization.id',
                    'shelters.id'
                ]
            })
            async.series([
                callback => this.authorizationsValidation(cUser, context, callback)
            ], callback)
        },
        beforeSave: function(newObject, oldObject, context, callback) {
            const buttonAction = context.action
            let action = ''
            switch (buttonAction) {
                case 'validate':
                    newObject.status.id = 'validated'
                    action = 'Validation Paiement'
                    break
                case 'refuse':
                    newObject.status.id = 'refused'
                    action = 'Refus Paiement'
                    break
                case 'justify':
                    newObject.status.id = 'incomplete'
                    action = 'En attente de justification'
                    break
            }
            newObject.comments.push({
                user: _.pick(context.user, ['id', 'name']),
                text: `${action} : ${newObject.amount} € - ${newObject.account.name}`,
                date: moment().format("YYYY-MM-DD HH:mm")
            })

            return callback(null, newObject, oldObject)
        },
        afterSave: async function(newObject, oldObject, context, callback) {
            const recipients = await getUsersWithAuthorization('paymentAuthorization')
            const defaultMail = prepareDefaultMail(context)

            const statusId = _.get(newObject, 'status.id')
            const content = {}

            switch (statusId) {
                case 'validated':
                    content.template = 'payer_validation_notification.html'
                    content.subject = `Paiement validé`
                    break
                case 'refused':
                    content.template = 'payer_rejection_notification.html'
                    content.subject = `Paiement refusé`
                    break
                case 'incomplete':
                    content.template = 'payer_justification_notification.html'
                    content.subject = `Demande de justification`
            }

            const mails = recipients.map(recipient => {
                return _.defaults(
                    {
                        to: recipient.mail && decrypt(recipient.mail),
                        content: content.template,
                        subject: { template: content.subject },
                        context: {
                            firstname: recipient.firstname,
                            applicationNumber: newObject.reliedFundApplicationNumber
                        }
                    },
                    defaultMail
                )
            })

            return global.mailer.sendMail(mails, (error) => {
                console.log(error)
                return callback()
            })
        }
    }
]
