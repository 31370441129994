import async from "async"
//import {generateFetchFieldListAction, GET_OBJECT_SUCCESS} from "../../../apps/KpModule/actions/api"
import {setDataList, setFieldListOptions, setFieldVisibility} from "../../../apps/KpModule/actions";
const _ = require("lodash")
const Errors = require('../../utils/Errors').default
const { propertyChangeComparator } = require("../../utils/functional")

// Attention to new machines: you must create a minimal collection respecting the importantInputCorrespondences
// to do that, use the job inputCorrespondences
export const importantInputCorrespondences = {
    Store: {
        internalIdentifier: "store", inputColumnName: "STORE", dataType: "type-string",
        header: "hic-store",
        fixed: true, fixedRequiredRules: true, requiredInFile: true, requiredInLine: true
    },
    LineType: {
        internalIdentifier: "lineType", inputColumnName: "LINETYPE", dataType: "type-string",
        header: "hic-lineType",
        fixed: false, fixedRequiredRules: false, requiredInFile: false, requiredInLine: false
    },
    Date: {
        internalIdentifier: "date", inputColumnName: "DATE", dataType: "type-date",
        header: "hic-date",
        fixed: true, fixedRequiredRules: true, requiredInFile: true, requiredInLine: true
    },
    TicketNo: {
        internalIdentifier: "ticketNo", inputColumnName: "TICKETNO", dataType: "type-string",
        header: "hic-ticketNo",
        fixed: true, fixedRequiredRules: true, requiredInFile: true, requiredInLine: true
    },
    LineNo: {
        internalIdentifier: "lineNo", inputColumnName: "NUMLIGNE", dataType: "type-string",
        header: "hic-lineNo",
        fixed: true, fixedRequiredRules: true, requiredInFile: true, requiredInLine: true
    },
    ProductCode: {
        internalIdentifier: "productCode", inputColumnName: "PRODUCTCODE", dataType: "type-string",
        header: "hic-productCode",
        fixed: true, fixedRequiredRules: false, requiredInFile: true, requiredInLine: false
    },
    Checkout: {
        internalIdentifier: "checkout", inputColumnName: "CHECKOUT", dataType: "type-string",
        header: "hic-checkout",
        fixed: true, fixedRequiredRules: false, requiredInFile: true, requiredInLine: true
    },
    Cashier: {
        internalIdentifier: "cashier", inputColumnName: "CASHIER", dataType: "type-string",
        header: "hic-cashier",
        fixed: true, fixedRequiredRules: false, requiredInFile: true, requiredInLine: true
    },
    Amount: {
        internalIdentifier: "amount", inputColumnName: "AMOUNT", dataType: "type-float",
        header: "hic-amount",
        fixed: false, fixedRequiredRules: false, requiredInFile: false, requiredInLine: false
    },
    Volume: {
        internalIdentifier: "volume", inputColumnName: "VOLUME", dataType: "type-integer",
        header: "hic-volume",
        fixed: false, fixedRequiredRules: false, requiredInFile: false, requiredInLine: false
    },
    ExchangeRate: {
        internalIdentifier: "exchangeRate", inputColumnName: "EXCHANGERATE", dataType: "type-float",
        header: "hic-exchangeRate",
        fixed: true, fixedRequiredRules: false, requiredInFile: false, requiredInLine: false
    }
};

export const dependencies = [
    {
        name: "InputCorrespondenceType",
        facets: ["codeName"],
        type: "static",
        fields: [
            {path: "id", type: "string"}
        ],
        objects: [
            {id: "required", code: "required", name: "Fixed and Required"},
            {id: "fixed", code: "fixed", name: "Fixed"},
            {id: "custom", code: "custom", name: "Custom"}
        ]
    }
]

export const entities = [
    {
        name: "InputCorrespondenceByImportType",
        fields: [
            {path: "code", unique: true},
            {path: "name"},
            {path: "inputCorrespondences", type: "InputCorrespondence", link: "OTM"},
            {path: "themeAxes", type: "InputCorrespondence", link: "MTM", nullable: true}
        ],
        cannotDeleteUsedColumn: function(object, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType
            if(action === 'C') return callback()
            const deletedColumns = oldObject.inputCorrespondences.filter(column => !object.inputCorrespondences.find(col => col.id === column.id))

            async.parallel(deletedColumns.map(column => callback => {
                if(column.fixed) return callback(new Errors.ValidationError(context.tc("fixedInputCorrespondenceCantBeDeleted")))
                if(column.requiredInFile) return callback(new Errors.ValidationError(context.tc("requiredInFileInputCorrespondenceCantBeDeleted")))
                const themeConfContext = {
                    group: context.group, fieldPath: ['id'],
                    query: {
                        $or : [
                            {'conditions.source': new global.ObjectID(column.id)},
                            {'mt.source': new global.ObjectID(column.id)},
                            {'mt.conditions.source': new global.ObjectID(column.id)},
                            {'nb.source': new global.ObjectID(column.id)},
                            {'nb.conditions.source': new global.ObjectID(column.id)},
                        ]
                    }
                }

                const subThemeQuery = {
                    group: context.group, fieldPath: ['id'],
                    query: {'conditions.source': new global.ObjectID(column.id)}
                }
                const subThemeElementQuery = {
                    group: context.group, fieldPath: ['id'],
                    query: {codeSource: new global.ObjectID(column.id)}
                }
                async.parallel({
                    themeConfigs: callback => global.app.S.ThemeConfig.find(themeConfContext, callback),
                    subThemes: callback => global.app.S.SubTheme.find(subThemeQuery, callback),
                    subThemeElements: callback => global.app.S.SubThemeElement.find(subThemeElementQuery, callback)
                }, (e, {themeConfigs, subThemes, subThemeElements}) => {
                    if (e) return callback(e)
                    if(themeConfigs.length) {
                        callback(new Errors.ValidationError(context.tc("inputUsedInThemeAndCanNotBeDeleted")));
                    }
                    else if(subThemes.length) {
                        callback(new Errors.ValidationError(context.tc("inputUsedInSubThemeAndCanNotBeDeleted")));
                    }
                    else if(subThemeElements.length) {
                        callback(new Errors.ValidationError(context.tc("inputUsedInSubThemeElementAndCanNotBeDeleted")));
                    } else {
                        callback()
                    }
                })
            }), callback)

        },
        validateSave: function(object, oldObject, context, callback) {
            async.series([
                callback => this.cannotDeleteUsedColumn(object, oldObject, context, callback),

            ], callback)
        }
    },
    {
        name: "InputCorrespondence",
        fields: [
            {
                path: "internalIdentifier",
                uniqueWith: ["inputCorrespondenceByImportType"],
                index: true,
                notEmpty: true,
                ps: {object: ["mongoFieldName"]}
            },
            {
                path: "inputColumnName",
                uniqueWith: ["inputCorrespondenceByImportType"],
                notEmpty: true
            },
            {
                type: "DataType",
                link: "MTO",
                nullable: false
            },
            {
                path: "newColumnName",
                f: function() {
                    return this.internalIdentifier;
                }
            },
            {
                path: "header"
            },
            {
                path: "analysisAxis",
                type: "boolean",
                default: false
            },
            {
                path: "analysisFilterAxis",
                type: "boolean",
                default: false
            },
            {path: "analysisLabel"},
            {path: "order", type: "integer", nullable: true},
            {
                path: "fixed",
                type: "boolean",
                default: false
            },
            {
                path: "fixedRequiredRules",
                type: "boolean",
                default: false
            },
            {
                path: "requiredInFile",
                type: "boolean"
            },
            {
                path: "requiredInLine",
                type: "boolean"
            },
            {
                path: "name",
                fieldPath: ["internalIdentifier"],
                f: function () {
                    return this.internalIdentifier;
                }
            }
        ],
        filters: [
            {
                name: "byFlowStructure",
                isDefault: false,
                query: (context) => {
                    const flowId = _.get(context, "data.id")
                    return flowId ? {inputCorrespondenceByImportType: new global.ObjectID(flowId)} : {_id: null}
                }
            },
            {
                name: "isAnalysisFilterAxis",
                isDefault: false,
                query: () => {
                    return {analysisFilterAxis: true}
                }
            }
        ],
        validateSave: function(inputCorrespondence, oldInputCorrespondence, context, callback) {

            const propertyChanged = propertyChangeComparator(inputCorrespondence, oldInputCorrespondence);

            if (inputCorrespondence.requiredInLine && !inputCorrespondence.requiredInFile) {
                return callback(new Errors.ValidationError(context.tc("correspondenceRequiredInFileWhenRequiredInLine")));
            }

            if (oldInputCorrespondence && oldInputCorrespondence.fixed && propertyChanged("internalIdentifier")) {
                return callback(new Errors.ValidationError(context.tc("theInternalIdentifierOfThisCorrespondenceCanNotBeChanged")));
            }

            if (oldInputCorrespondence && oldInputCorrespondence.fixedRequiredRules && propertyChanged(["requiredInFile", "requiredInLine"])) {
                return callback(new Errors.ValidationError(context.tc("theRequiredConfigurationOfThisCorrespondenceCanNotBeChanged")));
            }

            callback(null);
        }
    }
]

export const module_ = {
    object: "InputCorrespondenceByImportType",
    tKey: "mTitle_inputCorrespondence",
    defaultSortBy : "code",
    defaultSortDirection : "ASC",
    category: {
        path: "dataflow",
        icon: "database"
    },
    viewMap: {
        dt: ["code", "name"],
        form: {
            fields: [
                {path:"code", editable: false},
                {path:"name"},
                {
                    path: "inputCorrespondences",
                    removable: true,
                    viewMap: {
                        dt: [
                            "inputColumnName",
                            "internalIdentifier",
                            {path: "dataType", display: "tKey"},
                            {path: "requiredInFile", width: 140},
                            {path: "requiredInLine", width: 140},
                            {path: "analysisAxis", width: 140, tKey: "elemDataAnalysisModule"},
                            {path: "analysisLabel", width: 120},
                            {path: "order"}
                            //{path: "fixed", initiallyNotVisible: true}
                        ],
                        form: {
                            fields: [
                                "inputColumnName",
                                "internalIdentifier",
                                {path: "dataType", display: "tKey", translate: true, sortList: false},
                                "requiredInFile",
                                "requiredInLine",
                                {
                                    path: "analysisAxis",
                                    tKey: "elemDataAnalysisModule",
                                    subscriptions: {
                                        onChange: (newValue, oldValue, {store}) => {
                                            store.dispatch(setFieldVisibility('e_inputCorrespondences.e_analysisLabel', !!newValue))
                                            store.dispatch(setFieldVisibility('e_inputCorrespondences.e_order', !!newValue))
                                        }
                                    }
                                },
                                {path: "analysisLabel"},
                                {path: "order"}
                            ]
                        }
                    },
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            if(newValue) {
                                store.dispatch(setDataList("m-S-inputCorrespondenceByImportType.InputCorrespondenceByImportType_themeAxes", newValue))
                                store.dispatch(setFieldListOptions("e_themeAxes", newValue.map(value => value.id)))
                            }
                        }
                    }
                },
                {path: "themeAxes", display: "internalIdentifier", filters: ["byFlowStructure"]}
            ]
        }
    },
    /*
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const objectMode = state.ui.objectMode
                if(objectMode === "editObject") {
                    const data = _.get(state, "edit.object.data")
                    store.dispatch(
                        generateFetchFieldListAction(
                            "m-S-inputCorrespondenceByImportType.InputCorrespondenceByImportType_themeAxes",
                            store.getState,
                            'form',
                            {data}
                        )
                    )
                } else store.dispatch(
                    setDataList(
                        "m-S-inputCorrespondenceByImportType.InputCorrespondenceByImportType_themeAxes",
                        []
                    )
                )
            }
        }
    ],
     */
}

export const inputCorrespondencesRef = function(callback) {
    global.app.S.InputCorrespondence.find({}, (e, inputCorrespondences) => {
        if (e) return callback(e);
        const myInputCorrespondencesRef = {
            inputCorrespondences,

            mappedByInternalId: _.mapKeys(inputCorrespondences, value => value.internalIdentifier),

            mappedByNewColumnName:  _.mapKeys(inputCorrespondences, value => value.newColumnName),

            mappedByColumnName: _.mapKeys(inputCorrespondences, value => value.inputColumnName)
        };
        callback(e, myInputCorrespondencesRef);
    });
}
