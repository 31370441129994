import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Accordion from './Accordion'
import Checkbox from './Checkbox'
import LabeledCheckbox from './LabeledCheckbox'
import RadioButton from './RadioButton'
import RankOptionsField from './RankOptions'
import Comment from './Comment'
import Conversation from './conversation'
import TimePicker from './TimePicker'
import DatePicker from './DatePicker'
import DateRange from './DateRange'
import Dropdown from './Dropdown'
import DtObjects from './DtObjects'
import StyledTable from './StyledTable'
import FilesField from './Files'
import SimpleFilesField from './SimpleFiles'
import SingleFile from './SingleFile'
import ReadOnly from './ReadOnly'
import ReadOnlyObject from './ReadOnlyObject'
import Tags from './Tags'
import CreatableTags from './CreatableTags'
import Text from './Text'
import Heading from './Heading'
import LabeledText from './LabeledText'
import Title from './Title'
import Title2 from './Title2'
import Title3 from './Title3'
import Cursor from './Cursor'
import TranslatedText from './TranslatedText'
import Number from './Number'
import TextArea from './TextArea'
import RichTextEditor from './RichTextEditor'
import Separator from './Separator'
import DualBoxList from './DualBoxList'
import MonthPicker from './MonthPicker'
import MonthPickerRange from './MonthPickerRange'
import Password from './Password'
import VideosContainer from './VideosContainer'
import ImagesContainer from './ImagesContainer'
import MediaContainer from './MediaContainer'
import Survey from "./Survey";
import SurveyResponse from "./SurveyResponse";
import MultipleCheckbox from "./MultipleCheckbox";
import PhoneNumberInput from './PhoneNumberInput';
import Toggle from "./Toggle";
import ColorPicker from "./ColorPicker";
import LabeledNumber from "./LabeledNumber";
import LabeledDropdown from "./LabeledDropdown";
import LogoPicker from "./LogoPicker";

function formFieldForType(type) {
    switch (type) {
        case'phoneNumber':
            return PhoneNumberInput
        case 'accordion':
            return Accordion
        case 'checkbox':
            return Checkbox
        case 'labeledCheckbox':
            return LabeledCheckbox
        case 'comment2':
            return Comment
        case 'timePicker':
            return TimePicker
        case 'datePicker':
            return DatePicker
        case 'dateRange':
            return DateRange
        case 'dropdownObject':
            return Dropdown
        case 'boundedIntegerInDropdown':
            return LabeledDropdown
        case 'dtObjects':
            return DtObjects
        case 'styledTable':
            return StyledTable
        case 'simpleFiles':
            return SimpleFilesField
        case 'files2':
            return FilesField
        case 'singleFile':
            return SingleFile
        case 'translatedText':
            return TranslatedText
        case 'readOnly':
            return ReadOnly
        case 'readOnlyObject':
            return ReadOnlyObject
        case 'tags':
            return Tags
        case 'creatableTags':
            return CreatableTags
        case 'text':
            return Text
        case 'heading':
            return Heading
        case 'labeledText':
            return LabeledText
        case 'title1':
            return Title
        case 'title2':
            return Title2
        case 'title3':
            return Title3
        case 'number':
            return Number
        case 'boundedInteger':
        case 'boundedNumber':
            return LabeledNumber
        case 'cursor':
            return Cursor
        case 'textarea':
            return TextArea
        case 'password':
            return Password
        case 'richTextEditor':
            return RichTextEditor
        case 'separator':
            return Separator
        case 'dualBoxList':
            return DualBoxList
        case 'monthPicker':
            return MonthPicker
        case 'monthPickerRange':
            return MonthPickerRange
        case 'video':
            return VideosContainer
        case 'image':
            return ImagesContainer
        case 'media':
            return MediaContainer
        case 'radioButton':
            return RadioButton
        case 'rankOptions':
            return RankOptionsField
        case 'multiCheckbox':
            return MultipleCheckbox
        case 'survey':
            return Survey
        case 'surveyResponse':
            return SurveyResponse
        case 'toggle':
            return Toggle
        case 'conversation':
            return Conversation
        case 'logoPicker':
            return LogoPicker
        case 'colorPicker':
            return ColorPicker
        default:
            return null
    }
}

class Field extends PureComponent {
    render() {
        const { field, t, objectMode, initialValues, editObjectValues, language, user, module, groupModel} = this.props
        const FormField = formFieldForType(field.type)

        if (FormField) {
            return <FormField field={field} t={t} objectMode={objectMode} initialValues={initialValues} editObjectValues={editObjectValues} language={language} user={user} module={module} groupModel={groupModel} />
        } else {
            return <div>Field type "{field.type}" not yet supported</div>
        }
    }
}

Field.propTypes = {
    field: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
}

export default Field
