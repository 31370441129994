import {setFieldVisibility, setFormButtons} from "../../../../../apps/KpModule/actions";
import {returnButton, saveButton, submitButton} from "../../utils";
import {getDataListList} from "../../../../../apps/KpModule/selectors";

export const module_ = {
    name: 'Submission',
    tKey: 'Dépôt dossier',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: true,
    removable: true,
    defaultSortBy: 'applicationNumber',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'object'},
            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'style'], translate: true},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true},
        ],
        form: {
            fields: [
                {path: 'object', required: true},
                {path: 'description', tKey: 'Présentation des difficultés rencontrées', type: 'richTextEditor', required: true},
                {path: 'associationUpdatedStatutes', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', required: true},
                {path: 'associationStatusNotice', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', required: true},
                {path: 'latestAssociationReport', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', required: true},
                {path: 'insuranceContract', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', required: true},
                {path: 'bankIdentityStatement', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', required: true},
                {path: 'associationInformationUpToDate', default: false},
                {path: 'shelterInformationUpToDate', default: false},
                {path: 'shelterCapacityInformationUpToDate', default: false},
                {path: 'landType', tKey: "Lieu concerné", display: 'name2', required: true, translate: true},
                {path: 'landTypeProof', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile'},
                {
                    path: 'needs',
                    tKey: 'Tableau des besoins',
                    type: 'accordion',
                    newable: true,
                    required: true,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp', tKey: "Besoin"},
                            { path: 'totalAmount', tKey: "Engagement (€)" },
                            { path: 'requestedAmount', tKey: "Demande (€)" }
                        ],
                        form: [
                            { path: 'natureOfHelp', tKey: "Besoin", required: true},
                            { path: 'totalAmount', tKey: "Engagement (€)", required: true, positiveWholeNumber: true },
                            { path: 'requestedAmount', tKey: "Demande (€)", required: true, positiveWholeNumber: true },
                            { path: 'supportingDocument', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'singleFile', tKey: "needSupportingDocument_submission"}
                        ]
                    }
                },
                {path: 'comments', tKey: 'Commentaires'},
                {path: 'statementOfHonestyAndAccuracy'},
                {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
                {path: 'organization', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            dataLists: ["user-shelters"],
            onOpen: ({state, store}) => {
                const objectMode = state.ui.objectMode
                if (objectMode === 'newObject') {
                    // Define the buttons for a new application form

                    store.dispatch(setFormButtons([
                        submitButton, saveButton, returnButton
                    ]))
                }

                const userShelters = getDataListList(
                    state,
                    'user-shelters'
                )

                //store.dispatch(setFieldVisibility( 'e_shelterInformationUpToDate', !!userShelters?.length))
                //store.dispatch(setFieldVisibility( 'e_shelterCapacityInformationUpToDate', !!userShelters?.length))
            }
        }
    },
    accesses: [
        {
            id: "user-shelters",
            entity: "Shelter",
            fieldPath: [
                "id"
            ],
            filters: ['byUser']
        }
    ],
    filters: [
        'userIsSubmitter',
        {
            name: 'byStatusForSubmission',
            query: () => {
                return {
                    status: { $in: ['draft', 'questioned'] }
                }
            }
        }
    ]
}
