import moment from "moment"
import _ from "lodash"
import Errors from "../../utils/Errors"
import {importantInputCorrespondences as ic} from "./inputCorrespondence"
import { translateName } from "../../../utils/i18n"
import {getAlertButtonsForStatusUser} from "./workflow"
import {basicContext} from "../../utils/contextUtils"

const getObjectByCode = (objects, code) => {
  return _.find(objects, o => o.code === code);
};

const getPath = (o, result, object, level, context) => {
    const key = _.findKey(o, _.isObject);
    if(key){
        let element = {tName: "NC"};
        if(level === 1){
            element = getObjectByCode(object.subThemes, key);
        }else if(level === 2){
            element = getObjectByCode(object.subThemeElements, key);
        }
        return getPath(o[key], result + " > " + context.translateName(element.name), element, level +1, context);
    }
    return result;
};

const groupElementName = (groupAxis, alert, context) => {
    switch (groupAxis.joinedEntity) {
        case "StoreAxis":
            return `${alert.store.code} - ${alert.store.tName}`;
        case "SubStoreAxis":
            return alert[groupAxis.code];
        case "OrganizationAxis":
            return translateName(alert.organizations[0].name, _.get(context, "language.id"));
        case "FamilyAxis":
            return translateName(alert.familys[0].name, _.get(context, "language.id"));
        case "ProductAxis":
            return alert.product ? alert.product.tName : "";
        default:
            return null;
    }
};

const groupElementCode = (groupAxis, alert, context) => {
    switch (groupAxis.joinedEntity) {
        case "StoreAxis":
            return alert.store.code
        case "SubStoreAxis":
            return alert[groupAxis.code];
        case "OrganizationAxis":
            return alert.organizations[0].code
        case "FamilyAxis":
            return alert.familys[0].code
        case "ProductAxis":
            return alert.product ? alert.product.code : "";
        default:
            return null;
    }
};


// these are the extra properties in the fieldPath, needed to resolve the habilitation filter
const habilitationFilterFieldPath = ["store.id", "store.organizations.id", "organizations.id", ...[
    "order", "profile", "step", "order", "maxOrder", "active", "multiOrder", "passiveAfter", "passiveBefore",
    "habFunction.id", "habFunction.habilitations.id", "habFunction.habilitations.isCentral", "habFunction.habilitations.organizationsJoin.id", "habFunction.habilitations.users.id"
].map(fp => "workflow.profileConfigs."+fp)];

async function getTicketLines(alert, context) {
    // only for alerts of type ticket
    if(alert[ic.TicketNo.header]) {
        const themesPromise = global.app.S.ThemeConfig.find({
            query: {},
            fieldPath: ["tName", "subThemes.name", "subThemes.subThemeElements.name"],
            group: context.group
        })

        const alertConfPromise = global.app.S.AlertConfiguration.get(
            alert.alertConfiguration.id,
            {
                fieldPath: ["themesKeys", "themeNames"],
                group: context.group
            }
        )

        const elemDatasPromise = global.app.S.ElemDataLine.find({
            query: {
                [ic.Date.header]: alert.baseDay,
                [ic.Store.header]: alert[ic.Store.header],
                [ic.TicketNo.header]: alert[ic.TicketNo.header]
            },
            fieldPath: ["product.code", "product.name"],
            group: context.group
        })

        const [alertConf, themes, elemDatas] = await Promise.all([alertConfPromise, themesPromise, elemDatasPromise])

        const themeJoinKeys = _.flatMap(alertConf.alertFields, (field => [field.themeJoin, field.denominatorTheme].filter(theme => theme).map(theme => theme.completeCode)))

        return _(elemDatas)
            .filter(elemData => !elemData.ticketLine)
            .flatMap(elemData => _(elemData)
                .pickBy(
                    (value, key) => _.some(themeJoinKeys, themePath => themePath.split('.')[0] === key)
                ).map(
                    (value, key) => ({
                        numLigne: Number(elemData[ic.LineNo.internalIdentifier]),
                        cashier: elemData[ic.Cashier.internalIdentifier],
                        product: elemData.product ? elemData.product.code + " - " + elemData.product.name : "",
                        productVolume: elemData.volume,
                        themeKey: getPath(value, context.translateName(getObjectByCode(themes, key.slice("theme-".length)).name), getObjectByCode(themes, key.slice("theme-".length)), 1, context),
                        themeValue: value.Mt ? value.Mt : "Only number"
                    })
                ).value()
            ).value();

    } else {
        return []
    }
}

export const entity = {
    name: "Alert",
    facets: ["comments", "files", "translatedField"],
    fields: [
        "code",
        {type: "Store", nullable: true},
        {type: "Organization", link: "MTM"},
        {type: "Product", nullable: true},
        {type: "Family", link: "MTM"},
        {type: "Reason"},
        "details",
        {path: "lineCount", type: "integer"},
        {path: "date.gte", type: "date"},
        {path: "date.lt", type: "date"},
        {
            path: "lastStatusUpdate",
            type: "date",
            ps: {object: [{$u: function(lastStatusUpdate) {
                return this.options.$v ? new Date() :lastStatusUpdate;
            }}]},
        },
        {
            path: "storeName",
            lazy: true,
            fieldPath: ["store.code", "store.name"],
            f: function () {
                return this.store ? `${this.store.code} - ${this.store.name}` : "";
            }
        },

        {
            path: "productName",
            lazy: true,
            fieldPath: ["familys.completeName", "product.name", "product.code"],
            f: function () {
                return this.product ? `${this.product.code} - ${this.product.name}` : this.familys.map(family => family.completeName).join(", ");
            }
        },

        {
            path: "organizationRef",
            lazy: true,
            fieldPath: ["organizations.completeName"],
            $f: function (alert, context, callback) {
                callback(null, alert.organizations.map(organization => organization.completeName).join(", "));
            }
        },

        {
            path: "familyRef",
            lazy: true,
            fieldPath: ["familys.name", "product.familys.name"],
            f: function () {
                if (! this.product) {
                    return _(this.familys).map(family => family.name).value();
                }
                else {
                    return _(this.product.familys).map(family => family.name).value();
                }
            }
        },
        /*
        {
            path: "storeAndSubStoreInfo",
            lazy: true,
            fieldPath: ["storeName"],
            f: function () {
                const notSubStoreHeaders = [ic.Store.header, ic.Date.header, ic.ProductCode.header];

                const subStoreInfo = _(this)
                    .keys()
                    .filter(key => _.startsWith(key, "hic-") && !_.includes(notSubStoreHeaders, key))
                    .map(key => `${_.upperFirst(key.replace("hic-", ""))}: ${this[key]}`)
                    .join(", ");

                return subStoreInfo ? `Store: ${this.storeName}, ${subStoreInfo}` : this.storeName;
            }
        },
         */

        {
            path: "alertConfigurationName",
            lazy: true,
            fieldPath: ["alertConfiguration.tName"],
            f: function() {
                return _.get(this, "alertConfiguration.tName");
            }
        },

        {
            path: "mesh",
            lazy: true,
            fieldPath: [
                "store.tName",
                "product.tName",
                "organizations.name",
                "familys.name",
                "alertConfiguration.groupAxes.name"
            ],
            $f: (alert, context, callback) => callback(
                null,
                global.app.S.AlertConfiguration.groupAxesWithStore(alert.alertConfiguration).map(
                    groupAxis => `${global.app.S.GroupAxis.translateName(groupAxis, context)} : ${groupElementName(groupAxis, alert, context)}`
                ).join(" | ")
            )
        },

        {
            path: "meshWithoutGroupAxis",
            lazy: true,
            fieldPath: [
                "store.tName",
                "product.tName",
                "organizations.name",
                "familys.name",
                "alertConfiguration.groupAxes.id"
            ],
            $f: (alert, context, callback) => callback(
                null,
                global.app.S.AlertConfiguration.groupAxesWithStore(alert.alertConfiguration).map(
                    groupAxis => groupElementName(groupAxis, alert, context)
                ).join(" | ")
            )
        },

        {
            path: "meshWithoutGroupAxisLinks",
            lazy: true,
            fieldPath: [
                "store.code",
                "product.code",
                "organizations.code",
                "familys.code",
                "alertConfiguration.groupAxes.id",
                "alertConfiguration.groupAxes.code"
            ],
            $f: (alert, context, callback) => {
                let bDate = moment.utc(alert["date"].gte).format("YYYY-MM-DD")
                let eDate = moment.utc(alert["date"].lt).subtract(1, "days").format("YYYY-MM-DD")
                return callback(
                    null,
                    global.app.S.AlertConfiguration.groupAxesWithStore(alert.alertConfiguration).map(
                        groupAxis => {
                            const code = groupElementCode(groupAxis, alert, context)
                            const name = groupElementName(groupAxis, alert, context)

                            return {
                                name,
                                path: `/tracking/m-S-elemDataAnalysis`,
                                query: {
                                    type: {id: groupAxis.id},
                                    value: code,
                                    period: [bDate, eDate]
                                }
                            }
                        }
                    )
                )
            }
        },

        // this property will be useless with the new alertProcessing module
        {
            path: "alertConfigurationReasonsIds",
            lazy: true,
            fieldPath: ["alertConfiguration.reason.id"],
            f: function() {
                return _.get(this, "alertConfiguration.reason", [])
                    .map(reason => reason.id)
            }
        },

        {
            path: "dataPeriod",
            f: function () {
                if (!this.date) return "";
                let bDate = moment.utc(this["date"].gte).format("YYYY-MM-DD");
                let eDate = moment.utc(this["date"].lt).subtract(1, "days").format("YYYY-MM-DD");
                return bDate === eDate ? bDate : `${bDate} -> ${eDate}`;
            }
        },
        {
            path: "dataPeriodColumn",
            f: function () {
                if (!this["date"]) return "";
                let bDate = moment.utc(this["date"].gte).format("YYYY-MM-DD");
                let eDate = moment.utc(this["date"].lt).subtract(1, "days").format("YYYY-MM-DD");
                return bDate === eDate ? bDate : `${bDate} ${eDate}`;
            }
        },
        {
            path: "themeValues",
            // lazy: true,
            f: function () {
                const themesOfOccurrence = _(this)
                    .keys()
                    .filter(key => _.startsWith(key, "theme-"))
                    .value();

                return _(this)
                    .pick(themesOfOccurrence)
                    .mapKeys((value, key) => key.slice("theme-".length))
                    .value();
            }
        },

        {
            path: "values",
            lazy: true,
            list: true,
            fieldPath: ["alertConfiguration.themesKeys"],
            f: function () {
                return _(this.alertConfiguration.themesKeys)
                    .uniq()
                    .map(key => _.round(_.get(this, key), 2)
                ).join(" | ");
            }
        },

        {
            path: "uniqValuesByDelay",
            lazy: true,
            list: true,
            fieldPath: ["alertConfiguration.themesKeysUniqByDelay"],
            f: function () {
                return _(this.alertConfiguration.themesKeysUniqByDelay)
                    .uniq()
                    .map(key => {
                        return [
                            key,
                            key === "lineCount"
                                ? _.get(this, key)
                                : _.round(_.get(this, key), 2)
                        ]
                    })
                    .reduce((acc, [key, value], index) => {
                        const splitKeys = key.split('.')
                        const lastElement = splitKeys[splitKeys.length - 1]
                        const columnType = key === 'lineCount' || lastElement === 'Nb'
                            ? 'number'
                            : 'amount'
                        return {
                            ...acc,
                            [`indicator${index + 1}`]: {
                                data: value,
                                type: columnType
                            }
                        }
                    }, {})
            }
        },

        {
            path: "keyAndValues",
            lazy: true,
            fieldPath: [
                "alertConfiguration.themesKeys",
                "alertConfiguration.completeThemesNames"
            ],
            f: function () {
                return _.zip(this.alertConfiguration.themesKeys, this.alertConfiguration.completeThemesNames)
                    .map(
                        ([key, name]) => `${name}: ${_.round(_.get(this, key), 2)}`
                    ).join(" | ");
            }
        },

        // specific display of workflow status
        {
            path: "stepStatus",
            lazy: true,
            fieldPath: ["status.order", "status.step", "workflow.profileConfigs", "workflow.profileConfigs.step", "workflow.profileConfigs.maxOrder"],
            f: function () {
                if (!this.workflow || !this.status || _.includes(["risk", "noRisk"], this.status.step)) return "-";
                const totalSteps = _.find(this.workflow.profileConfigs, {step: "inProgress"}).maxOrder +1;
                const currentStep = _.includes(["pending", "review"], this.status.step) ? "1" : this.status.order +1;
                return `${currentStep}/${totalSteps}`;
            }
        },

        {
            path: "possibleUsers",
            lazy: true,
            fieldPath: [
                "store", "organizations", "alertConfiguration.habFunctionHabilitation", "alertConfiguration.habFunctionHabilitation.habilitations",
                "alertConfiguration.habFunctionHabilitation.habilitations.isCentral", "alertConfiguration.habFunctionHabilitation.habilitations.habFunction",
                "alertConfiguration.habFunctionHabilitation.habilitations.organizationsJoin.id"
            ],
            f: function () {
                return _(this.alertConfiguration.habFunctionHabilitation)
                    .flatMap("habilitations")
                    .filter(habilitation => {
                        if ( (habilitation.habFunction.organizationAxisJoin.id || habilitation.habFunction.organizationAxisJoin.oid).toString() === global.app.S.CentralAxis.objects[0].id) return true;
                        if ( ! habilitation || ! habilitation.organizationsJoin || habilitation.organizationsJoin.length === 0 ) return false;
                        const habilitationOrganizationsIds = habilitation.organizationsJoin.map(org => org.id)

                        return _.some(this.organizations, organization => habilitationOrganizationsIds.some(habilitationOrgId => organization.id === habilitationOrgId)) ||
                            (this.store && habilitationOrganizationsIds.some(habilitationOrgId => this.store.id === habilitationOrgId)) ||
                            (this.store && _.some(this.store.organizations, organization => habilitationOrganizationsIds.some(habilitationOrgId => organization.id === habilitationOrgId) ));
                    })
                    .map(habilitation => habilitation.user)
                    .value()
            }
        },

        {
            path: "ticketsLines",
            lazy: true,
            $f: function (alert, context, callback) {
                getTicketLines(alert, context)
                    .then(ticketLines => callback(null, ticketLines))
                    .catch(error => callback(error))
            }
        }
    ],
    indexes:[
        {key: {"_id": 1, "group": 1}},
        {key: {"alertConfiguration": 1, "group": 1}},
        {key: {"alertConfiguration": 1, "date.gte": 1, "date.lt": 1, "group": 1}},
        {key: {"date.gte" : 1, "date.lt" : 1, "group": 1}},
        {key: {"workflow": 1, "date.gte" : 1, "date.lt" : 1, "group": 1}}
    ],
    ps: {
        context: [{$$u: function(context, callback) {
                // adds information

                // TODO move this logic to something like objectInternalFieldPath in the match filters
                // today objectFieldPath modify the fieldPath and sends data to client

                if(!context.receivedExtraFieldPath && (_.get(context, 'module.id') === "m-S-alert")) {
                    // KLUDGE getting here twice per request
                    context.receivedExtraFieldPath = true;
                    context.internalFieldPath.push(...habilitationFilterFieldPath);
                }

                callback(null, context);
            }}]
    },
    validateSave: (alert, oldAlert, context, callback) => {
        if(context.automaticValidation) return callback()
        const reasonMismatch = alert.reason?.automatic && !oldAlert?.reason?.automatic;
        if(reasonMismatch) return callback(new Errors.ValidationError(context.tc("chooseAutomaticReason")));
        global.app.S.StatusUser.find(
            {
                ...basicContext(context),
                fieldPath: ['id', 'step', 'active'],
                query: {
                    alert: new global.ObjectID(alert.id),
                    user: new global.ObjectID(_.get(context, 'user.id'))
                }
            },
            (e, statusUsers) => {
                if (e) return callback(e)
                const btns = getAlertButtonsForStatusUser(
                    statusUsers.find(
                        statusUser => statusUser.active === true
                    ) || statusUsers[0]
                )
                if (
                    btns
                        .filter(btn => btn.action)
                        .map(btn => btn.action)
                        .some(action => action === context.action)
                ) {
                    callback()
                } else {
                    return callback(new Errors.ValidationError(context.tc("actionNotAllowed")))
                }
            }
        )
    }
}
