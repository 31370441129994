const { translateName } = require("../../../utils/i18n");
const _ = require("lodash");

const translate = (object, context) => {
    switch (object.joinedEntity) {
        case "FamilyAxis":
        case "OrganizationAxis":
            return translateName(object.name, _.get(context, "language.id"));
        case "StoreAxis":
            return context.tc && context.tc(object.tKey)
        default:
            return "Invalid joinedEntity";
    }
};

const joinParams = [
    {
        entityName: "FamilyAxis",
        childEntityName: "Family"
    },
    {
        entityName: "OrganizationAxis",
        childEntityName: "Organization"
    },
    {
        entityName: "StoreAxis",
        childEntityName: "Store"
    }
];

export const entity = require("./../../utils/entityJoin").joinEntities({
    name: "ComparisonAxis",
    fields: [
        "code",
        "name",
        {
            path: "childOfAxisEntityName",
            fieldPath: ["joinedEntity"],
            f: function() {
                return joinParams.find(o => o.entityName === this.joinedEntity).childEntityName;
            }
        },
        {
            path: "tName",
            fieldPath: ["name"],
            $f: (object, context, callback) => {
                callback(null, translate(object, context));
            }
        }
    ],
    filters: [
        {
            name: "byScoredObject",
            isDefault: false,
            client: false,
            match: function (object, context) {
                const scoredObjectEntity = _.get(context, "clientContext.data.scoredObject.joinedEntity")
                switch (scoredObjectEntity) {
                    case "StoreAxis":
                        return object.joinedEntity === "OrganizationAxis"
                    case "OrganizationAxis":
                    case "FamilyAxis":
                        return false
                    case "ProductAxis":
                        return object.joinedEntity === "FamilyAxis"
                    default:
                        return true
                }
            }
        },
    ]
}, joinParams.map(o => o.entityName))
