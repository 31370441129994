import async from 'async'
import _ from "lodash"
import moment from 'moment'
import path from "path"
import Errors from '../../../utils/Errors'
import {basicContext} from "../../../utils/contextUtils"
import {decrypt} from "../../../utils/crypto"
import {returnButton} from "../utils"
import {prepareListButton, generateReportButton} from "./utils"
import {generatePresentationTable} from './presentationDocument'

export const entities = [
    {
        name: 'LiberalityPresentation',
        facets: [
            {name: 'files', linkType: 'OTO', path: 'table'},
            {name: 'files', linkType: 'OTO', path: 'report'},
        ],
        fields: [
            {path: 'recipients', type: 'CUser', link: 'MTM', nullable: true},
            'presentationDescription',
            'reportDescription',
            {type: 'LiberalityFile', link: 'MTM'},
            {path: 'filesNumber', type: 'integer', nullable: true},
            {path: 'presentationDate'},
            {path: 'updatedAt', type: 'date', nullable: true},
            {path: 'updatedBy', type: 'User', nullable: true},
            {path: 'reportGeneratedBy', type: 'User', nullable: true},

            'status',
            {
                path: "buttons",
                fieldPath: ['status'],
                $f: function (presentation, context, callback) {
                    const { moduleId } = context.clientContext;
                    if(presentation.status === 'closed') return callback(null, [returnButton])
                    if(moduleId === 'm-C-presentation') return callback(null, [prepareListButton, returnButton])
                    if(moduleId === 'm-C-report' && presentation.status !== 'closed' ) {
                        return callback(null, [generateReportButton, returnButton])
                    }
                    return callback(null, [returnButton])
                }
            }
        ],
        requiredAuthorization: async (context, callback) => {
            const functions = await global.app.C.Function.find({
                ...basicContext(context),
                query: {},
                fieldPath: ['id'],
                filters: ['liberalityProcessAdministrator']
            })

            const cUser = await global.app.C.CUser.collection.findOne({
                kpUser: global.ObjectID(context.user.id),
                functions: {$elemMatch: {$in: functions.map(func => global.ObjectID(func.id))}}
            })
            if(!cUser) return callback(new Errors.ValidationError("Autorisation requise manquante"))
            return callback()
        },
        emptyFilesField: (object, callback) => {
            if(!!object.liberalityFiles.length) return callback()

            return callback(new Errors.ValidationError("Une présentation doit contenir au moins un dossier"))
        },
        updateExistingPresentation: async (object, oldObject, callback) => {
            let addedFiles = oldObject
                ? object.liberalityFiles.filter(newFile => !object.liberalityFiles.some(oldFile => oldFile.id === newFile.id ))
                : object.liberalityFiles

            if(!addedFiles.length) return callback()

            const pendingPresentation = await global.app.C.LiberalityPresentation.collection.findOne({
                _id: {$ne: global.ObjectID(object.id)},
                presentationDate: {$gte: object.presentationDate}
            })

            if(pendingPresentation) {
                const formattedDate = moment(pendingPresentation.presentationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                return callback(new Errors.ValidationError(`Une présentation est déjà programmée pour le ${formattedDate} `))
            }
            return callback()
        },
        cannotRemoveProcessedFiles: async (object, oldObject, callback) => {
            if(!oldObject) return callback()
            const removedFiles = oldObject.liberalityFiles
                .filter(file => ['validated', 'refused', 'incomplete'].includes(file.status.id))
                .filter(oldFile => !object.liberalityFiles.some(newFile => newFile.id === oldFile.id ))

            if(!removedFiles?.length) return callback()

            return callback(new Errors.ValidationError("Vous ne pouvez pas retirer les dossier validés ou refusés"))

        },
        validateSave: async function(object, oldObject, context, callback){
            async.series([
                callback => this.requiredAuthorization(context, callback),
                callback => this.emptyFilesField(object, callback),
                callback => this.cannotRemoveProcessedFiles(object, oldObject, callback),
                callback => this.updateExistingPresentation(object, oldObject, callback),
            ], callback)
        },
        beforeSave: async function(object, oldObject, context, callback){
            const action = context.action
            try {
                object.updatedAt = new Date()
                object.updatedBy = context.user
                if(action === 'prepareList') {
                    object.table = await generatePresentationTable('table', object, context)
                    object.filesNumber = object.liberalityFiles.length
                    await this.updateFilesStatus(object, oldObject, context)
                    return callback(null, object, oldObject)
                }

                if(action === 'generateReport') {
                    object.reportGeneratedBy = context.user
                    object.report = await generatePresentationTable('report', object, context)
                    const unprocessedFiles =  object.liberalityFiles.filter(file => file.status.id === 'awaitingDecision')
                    return global.app.C.LiberalityFile.collection.updateMany(
                        { _id: { $in: unprocessedFiles.map(file => global.ObjectID(file.id)) } },
                        {$set: {status: 'readyToBePresented'}},
                        error => {
                            if(error) return callback(error)
                            object.liberalityFiles = object.liberalityFiles.filter(file => ['validated', 'rejected'].includes(file.status.id))
                            object.status = 'closed'
                            return callback(null, object, oldObject)
                        }
                    )
                }
                return callback(null, object, oldObject)
            } catch (e) {
                return callback(e)
            }

        },
        afterSave: async function(object, oldObject, context, callback){
            const action = context.action
            if(action && action !== 'generateReport') {
                return callback()
            }

            this.handleMailing(object, context)

            return callback()

        },
        validateDelete: async function(object, context, callback){
            const files = await global.app.C.LiberalityFile.collection.find({ _id: { $in: object.liberalityFiles.map(id => global.ObjectID(id))}}).toArray()
            const hasProcessedFile = files.some(file => ['validated', 'refused'].includes(file.status))

            if(hasProcessedFile) return callback(new Errors.ValidationError("Vous ne pouvez pas supprimé une présentation qui contient des dossiers traités"))

            return callback(null, object)
        },
        afterDelete: async function(object, context, callback){
            const files = await global.app.C.LiberalityFile.collection.find({ _id: { $in: object.liberalityFiles.map(id => global.ObjectID(id))}}).toArray()
            const awaitDecisionFiles = files.filter(file => file.status === 'awaitingDecision')

            if(!awaitDecisionFiles.length) return callback()

            global.app.C.LiberalityFile.collection.updateMany(
                { _id: { $in: awaitDecisionFiles.map(file => file._id) } },
                {$set: {status: 'readyToBePresented'}},
                callback
            )
        },
        updateFilesStatus: async (object, oldObject) => {
            await async.parallel(
                [
                    callback => global.app.C.LiberalityFile.collection.updateMany(
                        {
                            _id: {
                                $in: object.liberalityFiles
                                    .filter(file => ['readyToBePresented', 'updated'].includes(file.status.id))
                                    .map(file => global.ObjectID(file.id))
                            }
                        },
                        {$set: {status: 'awaitingDecision'}},
                        callback
                    ),
                    callback => {
                        if(!oldObject) return callback()
                        const removedFiles = oldObject.liberalityFiles
                            .filter(file => file.status.id === 'awaitingDecision')
                            .filter(oldFile => !object.liberalityFiles.some(newFile => newFile.id === oldFile.id ))
                        if(removedFiles.length === 0) return callback()
                        global.app.C.LiberalityFile.collection.updateMany(
                            { _id: { $in: removedFiles.map(file => global.ObjectID(file.id)) } },
                            {$set: {status: 'readyToBePresented'}},
                            callback
                        )
                    }
                ]
            )
        },
        handleMailing: (object, context) => {
            const mails = prepareEmails(object, context)

            global.mailer.sendMail(mails, (error) => {
                if(error) console.log(error)
            })

        }
    }
]


function prepareEmails(object, context) {
    const defaultMail = prepareDefaultMail(object, context)

    return object.recipients.map(recipient => {
        return _.defaults({
            to: decrypt(recipient.mail),
            context: {
                presentationDate: moment(object.presentationDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                firstname:recipient.firstname,
            }
        }, defaultMail)
    })
}


function prepareDefaultMail(object, context) {

    const replyTo = context.group.useNoReply
        ? context.group.noReply
        : 'support@keenpoint.com'

    const alias = context.group.useNoReply
        ? context.group.alias
        : 'Kp Support'

    return {
        from: `"${alias}"${replyTo}`,
        replyTo: replyTo,
        content: 'reportMail.html',
        subject: {template: `Relevé de décisions : ${moment(object.presentationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`},
        templateDir: path.join(global.appRoot, global.isProd ? 'buildServer' : 'src' , '/server/models/cnda/liberality/templates'),
        attachments: [
            {
                id: new global.ObjectID(object.report.id),
                filename: object.report.filename
            }
        ],
        verbose: {
            general: true,
        }
    }
}
