import {getButtonsForModule} from "./utils";
import _ from "lodash";
import path from "path";
import moment from "moment";
import {adhesionApplicationStatusObjects} from "../staticEntities";
import {basicContext} from "../../../utils/contextUtils";
import {decrypt} from "../../../utils/crypto";
import Errors from "../../../utils/Errors";
import crypto from "crypto";

export const entities = [
    {
        name: 'AdhesionCapacityByAnimalType',
        fields: [
            'AnimalType',
            {path: 'number', type: 'integer'},
        ]
    },
    {
        name: 'AdhesionShelter',
        fields: [
            'name',
            'address',
            'additionalAddress',
            'zipCode',
            'city',
            {
                path: 'country', ps: {
                    object: [{
                        $u: function () {
                            return 'FRANCE'
                        }
                    }]
                }
            },
            'mail',
            'phone',
        ]
    },
    {
        name: 'AdhesionCapacityByActivity',
        fields: [
            'AnimalType',
            {path: 'activity', type: 'OrganizationActivity'},
            {path: 'number', type: 'integer'},
        ]
    },
    {
        name: 'Adhesion',
        facets: [
            {name: 'files', path: 'contactID'},
            {name: 'files', path: 'situationNotice'},
            {name: 'files', path: 'updatedStatus'},
            {name: 'files', path: 'balanceSheetsAndIncomeStatements'},
            {name: 'files', path: 'officialStatement'},
            {name: 'files', path: 'CERFA'},
            {name: 'files', path: 'DDPVisitReports'},
            {name: 'files', path: 'lastGeneralMeeting'},
            {name: 'files', path: 'visitReport'},
            {name: 'files', path: 'documents'},
            'comments'
        ],
        fields: [
            {path: 'applicationNumber', type: 'string', unique: true},
            {path: 'mail', encrypted: true, unique: true},
            {path: 'civility', type: 'Civility'},
            {path: 'firstname'},
            {path: 'lastname'},
            {path: 'phone'},
            {path: 'organizationPresident', type: 'boolean'},
            {path: 'organizationName'},
            {path: 'organizationAddress'},
            {path: 'organizationAdditionalAddress'},
            {path: 'organizationZipCode'},
            {path: 'organizationCity'},
            {path: 'organizationCountry'},
            {path: "rna"},
            {path: "sheltersNumber", type: "integer"},
            {path: 'generalInterestRecognition', type: 'YesNo'},
            {path: 'publicUtilityRecognition', type: 'YesNo'},
            {path: 'hasEndowmentFund', type: 'YesNo'},
            {path: 'hasDUERP', type: 'YesNo'},
            {path: 'DUERPUpdateDate', type: 'date', nullable: true},


            {path: 'employeesNumber', type: 'integer', nullable: true},
            {path: 'electedOfficialsNumber', type: 'integer', nullable: true},
            {path: 'volunteersNumber', type: 'integer', nullable: true},
            {path: 'investigatorsNumber', type: 'integer', nullable: true},
            {path: 'animalTypes', type: 'AnimalType', link: 'MTM'},
            {
                path: "adhesionCapacityByAnimalType",
                type: "AdhesionCapacityByAnimalType",
                link: {
                    type: 'OTM',
                    onParent: true,
                }
            },
            {
                path: 'adhesionCapacityByActivity',
                type: 'AdhesionCapacityByActivity',
                link: {
                    type: 'OTM',
                    onParent: true,
                }
            },

            {type: 'AdhesionShelter', link: 'OTM'},
            {path: 'visitors', type: 'CUser', link: 'MTM', nullable: true},
            {type: 'OrganizationSize', nullable: true},

            {path: 'paymentDate', type: 'date', nullable: true},
            {path: 'status', type: 'AdhesionApplicationStatus'},
            {
                path: "sequence",
                unique: true,
                ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "adhesionApplicationSequence",
                        formatResult: function(result) {
                            return result.toString().padStart(4, '0') // Formats the sequence number, e.g., "0001"
                        }
                    }]
                }
            },
            {
                path: "buttons",
                fieldPath: ['status.id'],
                $f: function (application, context, callback) {
                    const buttons = getButtonsForModule(application, context);
                    callback(null, buttons);
                }
            },
            {path: 'createdAt', type: 'date'},
        ],
        filters: [
            {
                name: "byUser",
                isDefault: false,
                async: true,
                query: (context, callback) => {
                    global.app.C.CUser.collection.findOne({kpUser: global.ObjectID(context.user.id)}, (e, cUser) => {
                        if(!cUser) return callback(null, {_id: null})
                        return callback(null, {mail: cUser.mail})
                    })
                }
            }
        ],
        ps: {
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                "sequence" // Ensures 'sequence' is included for sequence generation on creation
                            ])
                        ];
                    }
                    callback(null, context)
                }
            }]
        },
        validateSave: async (object, oldObject, context, callback) => {
            const moduleId = _.get(context, 'clientContext.moduleId')
            if(moduleId !== 'm-C-application') return callback()

            const action = context.action
            const actionButton = object.buttons.find(button => button.action === action)

            const params = ['visitValidated', 'visitRejected'].includes(actionButton.nextStatus)
                ? {filter: 'eligibleToVisit', errorMsg: "Action réservée aux utilisateurs avec l'authorisation «Eligible Adhésion - Visite»"}
                : {filter: 'adhesionProcessAdministrator', errorMsg: "Action réservée aux utilisateurs avec l'authorisation «Eligible Adhésion - Administration»"}

            const functions = await global.app.C.Function.find({
                ...basicContext(context),
                query: {},
                fieldPath: ['id'],
                filters: [params.filter]
            })

            const cUser = await global.app.C.CUser.collection.findOne({
                kpUser: global.ObjectID(context.user.id),
                functions: {$elemMatch: {$in: functions.map(func => global.ObjectID(func.id))}}
            })
            if(!cUser) return callback(new Errors.ValidationError(params.errorMsg))
            return callback()
        },
        beforeSave: (object, oldObject, context, callback) => {
            if (context.restAction && context.restAction.crudType === "C") {
                object.status = {id: 'filed'}

                object.comments = [{
                    user: null,
                    text: "Volet Administratif : Enregistré",
                    date: moment().format("YYYY-MM-DD HH:mm")
                }]

                const currentYear = new Date().getFullYear()
                object.applicationNumber = `ADHE-${currentYear}-${object.sequence}`
                object.createdAt = new Date()
                return callback(null, object, oldObject)
            }

            object.lastComment = _.last(_.orderBy(object.comments, 'date')).text

            const action = context.action
            if(action === 'save') return callback(null, object, oldObject)
            const actionButton = object.buttons.find(button => button.action === action)
            object.status = {id: actionButton.nextStatus}
            const statusObject = adhesionApplicationStatusObjects.find(status => status.id === object.status.id)
            object.comments.push({
                user: _.pick(context.user, ['id', 'name']),
                text: statusObject.name,
                date: moment().format("YYYY-MM-DD HH:mm")
            })
            return callback(null, object, oldObject)
        },
        afterSave: async function(object, oldObject, context, callback){
            const action = context.action
            if(action === 'save') return callback()

            try {
                await this.createUserObject(object, oldObject, context)
                await this.handleMailing(object, context)

                return callback()
            } catch (error) {
                callback(error)
            }
        },
        createUserObject: async (object, oldObject, context) => {
            const previousStatus = _.get(oldObject, 'status.id')
            const currentStatus = _.get(object, 'status.id')
            if(previousStatus === 'filed' && ['incomplete', 'preValidated'].includes(currentStatus)) {
                const cUser = await global.app.C.CUser.collection.findOne({mail: object.mail})
                const profile = await global.db.collection('profile').findOne({name: "Dossier d'Adhésion"})

                const authorizations = []
                if(profile) authorizations.push({id: profile._id.toString()})

                if(!cUser) {

                    const buf = crypto.randomBytes(20);

                    const customToken = buf.toString('hex')

                    context.customToken = customToken

                    await global.app.C.CUser.save(
                        {
                            ..._.omit(object, ['id']),
                            status: 'waiting',
                            language: {id: 'fr'},
                            active: true,
                            authorizations
                        },
                        {
                            ...basicContext(context),
                            customPasswordInitializationMail: true,
                            customToken,
                            action: 'validate',
                            fieldPath: [
                                'civility.id',
                                'firstname', 'lastname', 'mail', 'mobile', 'phone', 'language.id',
                                'organizationReferent',
                                'liberalityReferent',
                                'functions.id', 'animalTypes.id',
                                'active', 'status', 'authorizations.id'
                            ]
                        }
                    )
                }
            }
        },
        handleMailing: async (object, context) => {
            let mails = []

            //Prepare email to notify the submitter
            const notificationEmail = prepareNotificationEmail(object, context)

            if(notificationEmail) mails.push(notificationEmail)

            const status = _.get(object, 'status.id')

            if(['filed', 'filed2', 'updated', 'updated2'].includes(status)) {
                const administrationEmails = await prepareAdministrationEmails(object, context)
                administrationEmails.forEach(mail => mails.push(mail))
            }

            if(status === 'visitInProgress') {
                const visitorsEmails = await prepareVisitEmails(object, context)
                visitorsEmails.forEach(mail => mails.push(mail))
            }

            return global.mailer.sendMail(mails, (error) => {
                if(error) console.log(error)
            })
        }

    }

]

function prepareDefaultMail() {
    return {
        from: `"CNDA - Processus Adhésion"adhesion@defensedelanimal.fr`,
        replyTo: "adhesion@defensedelanimal.fr",
        templateDir: path.join(global.appRoot, global.isProd ? 'buildServer' : 'src' , '/server/models/cnda/adhesion/templates'),
        verbose: {
            general: true,
        }
    }
}

function getNotificationEmailContent(object) {
    let content = {}
    const status = _.get(object, 'status.id')
    switch (status) {
        case 'filed':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Enregistré`
            content.template = 'user_submission_filed.html'
            break
        case 'incomplete':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Compléments  demandés`
            content.template = 'user_submission_incomplete.html'
            break
        case 'updated':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Mis à jour`
            content.template = 'user_submission_updated.html'
            break
        case 'preValidated':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Validé`
            content.template = 'user_submission_preValidated.html'
            break
        case 'filed2':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Technique : Enregistré`
            content.template = 'user_submission_filed2.html'
            break
        case 'adjourned':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Technique : Compléments  demandés`
            content.template = 'user_submission_adjourned.html'
            break
        case 'adjournedBD':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Technique : Compléments  demandés`
            content.template = 'user_submission_adjourned.html'
            break
        case 'updated2':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Technique : Mis à jour`
            content.template = 'user_submission_updated2.html'
            break
        case 'completed':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Technique : Validé`
            content.template = 'user_submission_completed.html'
            break
        case 'visitInProgress':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Visite : Demandée`
            content.template = 'user_submission_visitInProgress.html'
            break
        case 'accepted':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Adhésion : Acceptée`
            content.template = 'user_submission_accepted.html'
            break
        case 'validated':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Adhésion : Validée`
            content.template = 'user_submission_validated.html'
            break
        case 'rejected':
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Adhésion : Refusée`
            content.template = 'user_submission_rejected.html'
            break
    }
    return content
}

function prepareNotificationEmail(object, context) {
    const defaultMail = prepareDefaultMail()
    const content = getNotificationEmailContent(object, context)

    if(!content.template) return null

    const statusId = _.get(object, "status.id")
    const groupModelId = _.get(context, "groupModel.id", "")

    let amount = 0

    const organizationSize = _.get(object, "organizationSize.id")

    switch (organizationSize) {
        case '1':
            amount = 50
            break
        case '2':
            amount = 100
            break
        case '3':
            amount = 150
            break
    }

    const mediaDirPath = path.join(global.appRoot, global.isProd ? 'buildServer' : 'src' , '/server/models/cnda/media')

    const bankIdentityStatement = statusId === 'accepted'
        ? [{path: mediaDirPath + '/RIB_ADHESIONS.pdf'}]
        : []

    return _.defaults({
        to: decrypt(object.mail),
        content: content.template,
        subject: { template: content.subject },
        context: {
            firstname: object.firstname,
            applicationNumber: object.applicationNumber,
            organization: object.organizationName,
            lastComment: object.lastComment,
            host: context.host,
            amount,
            visitors: object.visitors?.map(visitor => visitor.completeInfo),
            baseUrl: global.isProd
                ? `https://${context.host}`
                : `http://localhost:3000`,
            groupModelUrl: global.isProd
                ? `https://${context.host}/business/${groupModelId}`
                : `http://localhost:3000/business/${groupModelId}`,
            tokenPresent: !!context.customToken,
            tokenAbsent: !context.customToken,
            customToken: context.customToken
        },
        attachments: [...bankIdentityStatement]
    }, defaultMail)
}

async function prepareAdministrationEmails(object, context) {
    const defaultMail = prepareDefaultMail()
    const recipients = await getAdministrators(context)
    const content = {}

    const status = _.get(object, 'status.id')

    switch (status) {
        case 'filed':
            content.template = 'admin_submission_filed.html'
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Enregistré`
            break
        case 'updated':
            content.template = 'admin_submission_updated.html'
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Administratif : Mis à jour`
            break
        case 'filed2':
            content.template = 'admin_submission_filed2.html'
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Technique : Enregistré`
            break
        case 'updated2':
            content.template = 'admin_submission_updated2.html'
            content.subject = `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Volet Technique : Mis à jour`
            break
    }

    return recipients.map(recipient => {
        return _.defaults(
            {
                to: decrypt(recipient.mail),
                content: content.template,
                subject: { template: content.subject },
                context: {
                    firstname: recipient.firstname,
                    applicationNumber: object.applicationNumber,
                    organization: object.organizationName
                }
            },
            defaultMail
        )
    })
}

async function prepareVisitEmails(object, context) {
    const defaultMail = prepareDefaultMail()
    const recipients = await getVisitors(object, context)
    const civility = _.get(object, 'civility.name', '')
    const mail = object.mail && decrypt(object.mail)
    const phone = object.phone && decrypt(object.phone)
    const applicantInfo = `${context.tc(civility)} ${object.firstname} ${object.lastname}, Courriel : ${mail} et Téléphone : +${phone}`
    return recipients.map(recipient => {
        return _.defaults(
            {
                to: decrypt(recipient.mail),
                content: 'visitor_submission_visitInProgress.html',
                subject: { template: `CNDA - Demande d'ahésion N° ${object.applicationNumber} : Visite : Demandée` },
                context: {
                    firstname: recipient.firstname,
                    applicationNumber: object.applicationNumber,
                    applicant: applicantInfo,
                    organization: object.organizationName
                }
            },
            defaultMail
        )
    })
}

async function getAdministrators(context) {
    const functions = await global.app.C.Function.find({
        ...basicContext(context),
        query: {},
        fieldPath: ['id'],
        filters: ['adhesionProcessAdministrator']
    })

    return await global.app.C.CUser.find({
        ...basicContext(context),
        query: {functions: {$elemMatch: {$in: functions.map(func => global.ObjectID(func.id))}}},
        fieldPath: ['id', 'firstname', 'mail']
    })
}

async function getVisitors(object, context) {

    return await global.app.C.CUser.find({
        ...basicContext(context),
        query: {_id: {$in: object.visitors.map(visitor => global.ObjectID(visitor.id))}},
        fieldPath: ['id', 'firstname', 'mail']
    })
}
