export const entity = {
    name: 'DemandNature',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: false, uniqueCode: true},
        'conversations'
    ],
}

export const module_ = {
    object: 'DemandNature',
    category: {
        path: 'process',
        icon: 'repeat'
    },
    tKey: 'category',
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {path: 'name', type: 'translatedText'}
        ],
        form: [
            {path: 'code', editable: false},
            {path: 'name', type: 'textarea', unique: true},
            {
                path: "conversations",
            }
        ],
    }
}