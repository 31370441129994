const _ = require('lodash')

export default {
    id: "S",
    name: "PilotFlow",
    description: "",
    languages: ['en', 'fr', 'es', 'it', 'nl'],
    entities: _.concat(
        [
            require("./slp/user").entity,
            require("./slp/organizationAxis").entity,
            require("./slp/organization").entity,
            require("./slp/store").entity,
            require("./slp/subStoreAxis").entity,
            require("./slp/familyAxis").entity,
            require("./slp/family").entity,
            require("./slp/product").entity,
            require("./slp/organizationAxisJoin").entity,
            require("./slp/organizationJoin").entity,
            require("./slp/familyAxisJoin").entity,
            require("./slp/comparisonAxis").entity,
            require("./slp/scoredElement").entity,
            require("./slp/familyJoin").entity,
            require("./slp/generalSettings").entity,
            require("./slp/habFunction").entity,
            require("./slp/habilitation").entity,
            require("./slp/alert").entity,
            require("./slp/alertAnalyse").entity,
            require("./slp/alertAnalysis").entity,
            require("./slp/alertAnalyseGroupElement").entity,
            require("./slp/scope").entity,
            require("./slp/scoreLine").entity,
            require("./slp/scoreAnalysis").entity,
            require("./slp/scoring").entity,
            require("./slp/groupAxis").entity,
            require("./slp/scoringConfig").entity,
            require("./slp/themeJoin").entity,
            require("./slp/alertConfiguration").entity,
            require("./slp/themeConfig").entity,
            require("./slp/subTheme").entity,
            require("./slp/importLog").entity,
            require("./slp/importConf").entity,
            require("./slp/reason").entity,
            require("./slp/alertReport").entity,
            require("./slp/themeAnalyseGroupElement").entity,
            require("./slp/themeAnalyse").entity,
            require("./slp/report").entity,
            require("./slp/dashboardElements/currentUserAlerts").entity,
            require("./slp/dashboardElements/currentUserAlertsInProgress").entity,
            require("./slp/dashboardElements/store/topStore100Discount").entity,
            require("./slp/dashboardElements/store/top90To100Discount").entity,
            require("./slp/dashboardElements/store/top80To90Discount").entity,
            require("./slp/dashboardElements/store/top70To80Discount").entity,
            require("./slp/dashboardElements/store/topNagativePmts").entity,
            require("./slp/dashboardElements/store/cashierCustomerWithSameEmail").entity,
            require("./slp/dashboardElements/store/currentUserExpenses").entity,
            require("./slp/dashboardElements/store/abandonmentReceipts").entity,
            require("./slp/dashboardElements/store/canceledReceipts").entity,
            require("./slp/dashboardElements/store/refundsWithoutReceipt").entity,
            require("./slp/dashboardElements/store/overSeventyPriceChange").entity,
            require("./slp/dashboardElements/store/clientsIndicators").entity,
            require("./slp/dashboardElements/store/attachmentRate").entity,
            require("./slp/dashboardElements/store/turnoverPerDay").entity,
            require("./slp/dashboardElements/store/turnoverPerSeller").entity,
            require("./slp/dashboardElements/store/topStoreSales").entity,
            require("./slp/dashboardElements/store/topRegionSales").entity,
            require("./slp/dashboardElements/store/cashierIsCustomer").entity,
            require("./slp/dashboardElements/store/customerGrossPurchaseH2500").entity,
            require("./slp/dashboardElements/store/customerTicketEH4").entity,
            require("./slp/dashboardElements/store/employeePurchaseH1000").entity,
            require("./slp/dashboardElements/region/drAbandonPerShop").entity,
            require("./slp/dashboardElements/region/drCanceledPerShop").entity,
            require("./slp/dashboardElements/region/drExpensePerShop").entity,
            require("./slp/dashboardElements/region/drRefundCashPerShop").entity,
            require("./slp/dashboardElements/region/drRefundCashWithoutTicketPerShop").entity,
            require("./slp/dashboardElements/region/drRefundWithoutTicketPerShop").entity,
            require("./slp/dashboardElements/region/drDiscountModificationPricePerShop").entity,
            require("./slp/dashboardElements/region/drManualDiscountReturnPerShop").entity,
            require("./slp/dashboardElements/region/drManualDiscountSalePerShop").entity,
            require("./slp/dashboardElements/region/drTurnoverPerShop").entity,
            require("./slp/dashboardElements/region/tillDifferencesAndCancellations").entity,
            require("./slp/alertFieldsModule").entity,
            require("./slp/delayUpdate").entity,
            require("./slp/referenceDate").entity
        ],
        require("./slp/report").dependencies,
        require("./slp/inputCorrespondence").entities,
        require("./slp/generalDependencies").default,
        require("./slp/scoringConfig").dependencies,
        require("./slp/alertConfiguration").dependencies,
        require("./slp/inputCorrespondence").dependencies,
        require("./slp/themeConfig").dependencies,
        require("./slp/subTheme").dependencies,
        require("./slp/workflowConfig").entities,
        require("./slp/importLog").dependencies,
        require("./slp/importConf").dependencies,
        require("./slp/alertReport").dependencies,
        require("./slp/engineEntities").entities,
        require("./slp/alertProcessing").entities,
        require("./slp/elemDataAnalysis").entities,
        require("./slp/dashboardAffectation").dependencies,
    ) ,
    modules: [
        // referential
        require("./slp/organizationAxis").module_,
        require("./slp/organization").module_,
        require("./slp/store").module_,
        require("./slp/familyAxis").module_,
        require("./slp/family").module_,
        require("./slp/product").module_,
        // dataFlow
        require("./slp/subStoreAxis").module_,
        require("./slp/inputCorrespondence").module_,
        require("./slp/importConf").module_,
        require("./slp/importLog").module_,

        // contiguration
        require("./slp/habFunction").module_,
        require("./slp/habilitation").module_,
        require("./slp/themeConfig").module_,
        require("./slp/subTheme").module_,
        require("./slp/alertConfiguration").module_,
        require("./slp/workflowConfig").module_,
        require("./slp/scoringConfig").module_,
        require("./slp/reason").module_,
        require("./slp/generalSettings").module_,
        require("./slp/alertFieldsModule").module_,
        require("./slp/delayUpdate").module_,
        require("./slp/referenceDate").module_,

        // tracking
        require("./slp/alertConfiguration").alertModule,
        require("./slp/alertAnalyse").module_,
        require("./slp/alertAnalysis").module_,
        require("./slp/alertProcessing").module_,
        require("./slp/scoring").module_,
        require("./slp/scoreLine").module_,
        require("./slp/scoreAnalysis").module_,
        require("./slp/report").module_,
        //require("./slp/alertReport").module_,
        require("./slp/themeAnalyse").module_,
        require("./slp/elemDataAnalysis").module_
    ]
};
