import _ from 'lodash'
import {justifyButton, validateButton, saveButton, returnButton, submitButton, acceptButton, refuseButton, examineButton, transmitPaymentsButton} from '../utils'
import path from "path";

export const getButtonsForModule = (applicationOrPayment, context) => {
    const statusId = _.get(applicationOrPayment, 'status.id');
    const { moduleId } = context.clientContext;
    let buttons = []

    switch (moduleId) {
        case 'm-C-submission':
            if (statusId === 'draft' || statusId === 'questioned') {
                buttons = [submitButton, saveButton, returnButton]
            }
            break
        case 'm-C-study':
            if (statusId === 'ongoing') {
                buttons = [
                    {
                        ...acceptButton,
                        tKey: 'Transmettre pour décision',
                    },
                    examineButton,
                    returnButton
                ]
            }
            break
        case 'm-C-decision':
            if (statusId === 'controlled') {
                buttons = [acceptButton, refuseButton, examineButton, returnButton]
            }
            break
        case 'm-C-reliefFundPayment':
            if (statusId === 'accepted') {
                buttons = [transmitPaymentsButton, returnButton]
            }
            break
        case 'm-C-reliefFundPaymentValidation':
            if(statusId === 'ongoing') {
                buttons = [validateButton, refuseButton, justifyButton, returnButton]
            }
    }

    // Adding a return button if not already included, to always allow going back
    if (!buttons.includes(returnButton)) {
        buttons.push(returnButton)
    }

    return buttons
}


export function prepareDefaultMail(context) {

    const replyTo = context.group.useNoReply
        ? context.group.noReply
        : 'support@keenpoint.com'

    const alias = context.group.useNoReply
        ? context.group.alias
        : 'Kp Support'

    return {
        from: `"${alias}"${replyTo}`,
        replyTo: replyTo,
        templateDir: path.join(global.appRoot, global.isProd ? 'buildServer' : 'src' , '/server/models/cnda/templates'),
        verbose: {
            general: true,
        }
    }
}

export async function getUsersWithAuthorization(authorization) {
    return await global.app.C.CUser.collection
        .aggregate([
            { $lookup: {
                    from: 'c.function',
                    let: { 'functionIds': '$functions' },
                    pipeline: [
                        { $match: {
                                [authorization]: true,
                                '$expr': { '$in': ['$_id', '$$functionIds'] } }
                        },
                    ],
                    as:'functions'
                }
            },
            {
                $match: {
                    $expr: { $gt: [{ $size: '$functions' }, 0] }
                }
            }
        ]).toArray()
}
