import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import classnames from "classnames";
import './DynamicStyledInput.css'
import ValidationUI from "../ValidationUI";

class DynamicStyledInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasFocus: false
        }
        this.handleAutoFill = this.handleAutoFill.bind(this)
    }
    componentDidMount() {
        /*
        const element = document.getElementById(`form-${this.props.path}`);
        element.removeAttribute('autocomplete')
         */
    }

    handleClickOutside = () => {
        this.setState({hasFocus: false})
    }

    handleAutoFill = e => {
        this.setState({
            hasFocus: e.animationName === "onAutoFillStart",
        });
    }

    render() {
        const {id, path, type, value, touched, error, onChange, required} = this.props
        return (
            <div className="DynamicStyledInput-group">
                <label
                    onClick={() => {
                        this.setState({hasFocus: true})
                        document.getElementById(id).focus();
                    }}
                    onMouseEnter={() => {
                        const element = document.getElementById(id)
                        element.classList.add("hovered");
                    }}
                    onMouseLeave={() => {
                        const element = document.getElementById(id)
                        element.classList.remove("hovered")
                    }}
                    className={classnames('DynamicStyledInput-label', { hasFocus: this.state.hasFocus || this.props.value })}
                >
                    {this.props.t(this.props.path)}{required && "*"}
                </label>
                <div>
                    <input
                        id={id}
                        type={type}
                        name={path}
                        onClick={() => this.setState({hasFocus: true})}
                        className="DynamicStyledInput-input"
                        value={value}
                        onAnimationStart={this.handleAutoFill}
                        onChange={onChange}
                    />
                </div>
                { touched && (error && <ValidationUI error={error}/>) }
            </div>
        )
    }
}

export default onClickOutside(DynamicStyledInput)
