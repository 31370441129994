export const entity = {
    name: "SubStoreAxis",
    fields: [
        "code",
        "name",
        {path: "isAlertAnalysisAxis", type: "boolean"},
        {path: "isScoringAnalysisAxis", type: "boolean"},
        {path: "isAlertReportAnalysisAxis", type: "boolean"},
    ]
}

export const module_ = {
    object: "SubStoreAxis",
    tKey: "mTitle_SubStoreAxis",
    category: {
        path: "dataflow",
        icon: "database"
    },
    defaultSortBy: "code",
    defaultSortDirection: "ASC",
    viewMap: {
        dt: [
            "code",
            {path: "name", type: "translatedText"}
        ],
        form: [
            "code",
            {path: "name", type: "textarea", placeholder: "fr::"},
            {path: "isAlertAnalysisAxis"},
            {path: "isScoringAnalysisAxis"},
            {path: "isAlertReportAnalysisAxis"}
        ]
    }
}
